import React, { Component } from 'react'
import { mergeClasses } from '../../utils'
import './Burger.scss'

// is a Component because we need the ref
class Burger extends Component {
  render () {
    const props = this.props
    return (
      <button
        className={mergeClasses(
          `burger ${props.open ? 'burger--open' : ''}`,
          props.className
        )}
        onClick={props.onClick}
      >
        <span />
        <span />
        <span />
      </button>
    )
  }
}

export default Burger
