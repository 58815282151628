import React, { Component } from 'react'
import Swiper from 'react-id-swiper/lib/custom'
import 'react-id-swiper/src/styles/scss/swiper.scss'
import './index.scss'
import ArrowButton from '../ArrowButton'
import CustomerSliderItem from './CustomerSliderItem'
import { exposeSliderMixin } from '../../utils'

class CustomerSlider extends exposeSliderMixin(Component) {
  render() {
    const options = {
      loop: false,
      slidesPerView: 1,
      freeMode: false,
      centeredSlides: true,
      spaceBetween: 0,
      speed: 600
    }

    return (
      <div ref={this.setScrollContainerRef} className="customer-slider">
        <div className="customer-slider__headline headline headline--centered">
          <h2>holzweg Kundenfamilie</h2>
        </div>
        <div
          className="customer-slider__container"
          onTransitionEnd={this.handleDrag}
        >
          <Swiper {...options} ref={this.onInit}>
            {this.props.slides.map((slide, index) => (
              <div className="swiper-slide" key={index}>
                {' '}
                {<CustomerSliderItem {...slide} />}{' '}
              </div>
            ))}
          </Swiper>
        </div>
        <div className="customer-slider__bottom">
          <div className="customer-slider__button-wrapper">
            <ArrowButton mods={this.getModsLeft()} onClick={this.prevSlide} />
            <ArrowButton mods={this.getModsRight()} onClick={this.nextSlide} />
          </div>
        </div>
      </div>
    )
  }
}

export default CustomerSlider
