import React from 'react'
import Link from 'gatsby-link'
import './index.scss'

import backgroundImage from '../../assets/Auftragsabwicklung.png'

const ErpModule = ({ title, text, textBelow, image, link }) => {

  return (
    <div
      className="erpmodule"
    // style={{ backgroundImage: `url(${backgroundImage})` }}
    >
      <div className="erpmodule__container">
        <div className="erpmodule__title">
          {title}
        </div>
        <div className="erpmodule__content">
          <div className="erpmodule__text">
            <span>{text}</span>
          </div>
          <span>
            <img className='erpmodule__image' src={image} />
          </span>
        </div>
        <div className='erpmodule__text-below'>
          {textBelow}
        </div>
            <div className="erpmodule__button-wrapper">
              <div className="erpmodule__button">
                <Link to={`${link}`} className="button button--color-black">
                  Mehr erfahren
                </Link>
              </div>
              <div className="erpmodule__button">
                <Link to={'/kontakt'} className="button button--color-black">
                  Demo anfordern
                </Link>
              </div>
            </div>
      </div>
    </div>
  )
}

export default ErpModule