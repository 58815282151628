import React, { useState, useRef, useEffect } from 'react';
import { Location } from '@reach/router';
import Link from 'gatsby-link';
import Burger from './Burger';
import Menu from './Menu';
import HolzwegLogo from '../HolzwegLogo';
import './index.scss';

const Header = (props) => {
  const [showMenu, setShowMenu] = useState(false);
  const menuRef = useRef(null);
  const burgerRef = useRef(null);


  useEffect(() => {
    const closeMenuOnOuterClick = (e) => {
      if (
        menuRef.current &&
        !menuRef.current.contains(e.target) &&
        burgerRef.current &&
        !burgerRef.current.contains(e.target)
      ) {
        setShowMenu(false);
      }
    };

    const handleKeyDown = (e) => {
      if (e.key === 'Escape' && showMenu) {
        setShowMenu(false);
      }
    };

    if (showMenu) {
      document.body.addEventListener('click', closeMenuOnOuterClick, { once: true });
      document.body.style.overflow = 'hidden';
      document.addEventListener('keydown', handleKeyDown);
    } else {
      document.body.style.overflow = '';
      document.removeEventListener('keydown', handleKeyDown);
    }

    return () => {
      document.body.removeEventListener('click', closeMenuOnOuterClick);
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [showMenu]);

  return (
    <Location>
      {({ location }) => {
        const LogoWrapper = location.pathname === '/' ? 'h1' : 'div';
        return (
          <header className="header">
            <div className="header__container">
              <LogoWrapper className="header__holzweg-logo-container">
                <Link to="/">
                  <HolzwegLogo className="header__holzweg-logo" color={props.color} />
                </Link>
              </LogoWrapper>
              <Burger ref={burgerRef} onClick={() => setShowMenu(!showMenu)} open={showMenu} />
            </div>
            <Menu ref={menuRef} showMenu={showMenu} toggleMenu={() => setShowMenu(!showMenu)} />
          </header>
        );
      }}
    </Location>
  );
};

export default Header;
