import React from 'react'
import { Link } from 'gatsby'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'
import { StaticQuery, graphql } from 'gatsby'

import CookieBanner from 'react-cookie-banner'
import Header from '../Header'
import Footer from '../Footer'
import './index.scss'
import { CookieConsentBanner } from '..'

const Layout = ({ children, color, margin }) => (
  <StaticQuery
    query={graphql`
      query SiteTitleQuery {
        site {
          siteMetadata {
            title
          }
        }
      }
    `}
    render={data => (
      <>
        <Helmet
          title={data.site.siteMetadata.title}
          meta={[
            { name: 'description', content: 'Sample' },
            { name: 'keywords', content: 'sample, something' }
          ]}
        >
          <html lang="de" />
          <script>
            {`
          (function(ss,ex){
            window.ldfdr=window.ldfdr||function(){
              (ldfdr._q=ldfdr._q||[]).push([].slice.call(arguments));
            };
            (function(d,s){
              fs=d.getElementsByTagName(s)[0];
              function ce(src){
                var cs=d.createElement(s);
                cs.src=src;
                cs.async=1;
                fs.parentNode.insertBefore(cs,fs);
              };
              ce('https://sc.lfeeder.com/lftracker_v1_'+ss+(ex?'_'+ex:'')+'.js');
            })(document,'script');
          })('p1e024Bnlj94GB6d');
        `}
          </script>
        </Helmet>
        <div className={`container ${margin ? 'container--no-header' : ''}`}>
          <Header color={color} />
          {children}
          <Footer />
        </div>
        <CookieConsentBanner />
      </>
    )}
  />
)

Layout.propTypes = {
  children: PropTypes.node.isRequired
}

export default Layout
