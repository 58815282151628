import React, { useState, useEffect, useRef } from "react";
import './index.scss';

const Testimonials = ({ testimonials }) => {
    const containerRef = useRef(null);
    const intervalRef = useRef(null);
    const delay = 25;
    const scrollIncrement = 0.3;
    const [manualScroll, setManualScroll] = useState(false);

    useEffect(() => {
        let i = 0;
        const container = containerRef.current;
        const scrollWidth = container.scrollWidth;
        
        const scrollTestimonials = () => {
            intervalRef.current = setInterval(() => {
                container.scrollTo(i, 0);
                i += scrollIncrement;
                if (i >= scrollWidth) {
                    i = 0;
                }
            }, delay);
        };

        if (container && !manualScroll) {
            scrollTestimonials();
        }

        // Event Listener für Interaktionen
        const handleInteraction = () => {
            setManualScroll(true);
            clearInterval(intervalRef.current);
        };

        // Touch-Events
        container.addEventListener("touchstart", handleInteraction);
        container.addEventListener("touchmove", handleInteraction);

        // Maus und Klick Events 
        container.addEventListener("mouseover", handleInteraction);
        container.addEventListener("click", handleInteraction);

        return () => {
            clearInterval(intervalRef.current);
            container.removeEventListener("touchstart", handleInteraction);
            container.removeEventListener("touchmove", handleInteraction);
            container.removeEventListener("mouseover", handleInteraction);
            container.removeEventListener("click", handleInteraction);
            };

        }, [manualScroll]);

    return (
        <>
            <div className="testimonials">
                <h2 className="testimonials__title">Das sagen unsere Kunden</h2>
                <p className="testimonials__subheading">Überzeugen Sie sich selbst, und schauen Sie bei unseren bisherigen Projekten vorbei</p>
                <div className="testimonials__container" ref={containerRef}>
                    {testimonials.map((testimonial, index) => (
                        <div className="testimonials__item" key={index}>
                            <div className="testimonials__item-top">
                                <span>"</span>
                            </div>
                            <div className="testimonials__item-content">
                                {testimonial.text}
                            </div>
                            <div className="testimonials__item-bottom">
                                <div className="testimonials__item-bottom-img">
                                    <img src={testimonial.image} alt="testimonial" />
                                </div>
                                <div className="testimonials__item-bottom-name">
                                    <div className="testimonials__item-bottom-name-company">
                                        {testimonial.company}
                                    </div>
                                    <div className="testimonials__item-bottom-name-name">
                                        {testimonial.name}
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </>
    );
};

export default Testimonials;
