import React from "react";
import './index.scss'

import img from '../../assets/individual.eglo-b2b.png'
import HoverButton from "../HoverButton";
import fullservice from '../../assets/projekte/alupress/fullservice.png'

const ImageList = () => {

    const gaEvent = () => {
        if (typeof gtag === 'function') {
          let date = Date.now();
          gtag('event', 'technologie_imglist-kontakt', {
            'time': date,
          });
        }
      };
      
    return (
        <div className="imagelist" id="start">
            <div className="imagelist__col__heading">
                <h2>Ihre Website kann mehr! Wie?</h2>
                <p className="imagelist__subheading">
                    Das volle Potential aus Ihrer Website holen!
                </p>
            </div>
            <div className="imagelist__container">
                <div className="imagelist__col">
                    <div className="imagelist__col__item">
                        <div className="imagelist__col__item-heading">
                            <h4>Optimierung interner Prozesse</h4>
                        </div>
                        <div className="imagelist__col__item-text">
                            <p>HR-Prozesse und Terminbuchungen, die interne Abläufe vereinfachen und automatisieren.</p>
                        </div>
                    </div>
                    <div className="imagelist__col__item">
                        <div className="imagelist__col__item-heading">
                            <h4>B2B-Integration und Portale </h4>
                        </div>
                        <div className="imagelist__col__item-text">
                            <p>Wir integrieren für Sie B2B-Portale und B2B-fokussierte Lösungen, die speziell auf Geschäftskunden ausgerichtet sind.</p>
                        </div>
                    </div>
                </div>

                <div className="imagelist__col">
                    {/* mid */}
                    <div className="imagelist__col__image">
                        <img src={fullservice} />
                    </div>
                    <HoverButton
                        dataTitle="Wieso warten?"
                        dataText="zum Kontakt"
                        link="/kontakt"
                        onClick={gaEvent()}
                    />
                </div>

                <div className="imagelist__col">
                    <div className="imagelist__col__item">
                        <div className="imagelist__col__item-heading">
                            <h4>Interaktive Tools und Analysen</h4>
                        </div>
                        <div className="imagelist__col__item-text">
                            <p>Wir entwickeln maßgeschneiderte, interaktive Website-Tools, die Ihre Marke hervorheben und neue sowie bestehende Kunden begeistern.</p>
                        </div>
                    </div>
                    <div className="imagelist__col__item">
                        <div className="imagelist__col__item-heading">
                            <h4> Benutzerorientierte Funktionen</h4>
                        </div>
                        <div className="imagelist__col__item-text">
                            <p>Mit DSGVO-konformen Tools wie Matomo und Google Analytics schaffen Sie personalisierte Nutzererlebnisse durch tiefgehende Zielgruppenanalysen.</p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="imagelist__bottom">
                <div className="imagelist__col__item">
                    <div className="imagelist__col__item-heading">
                        <h4>Effiziente Datenverarbeitung</h4>
                    </div>
                    <div className="imagelist__col__item-text">
                        <p>Die nahtlose Integration und Austausch von Kundendaten aus CRM-, ERP- und Newsletter-Tools vermeidet nerviges Copy&Paste. Wir schauen, was für Ihre Situation am geeignetsten ist.</p>
                    </div>
                </div>
                <div className="imagelist__col__item">
                    <div className="imagelist__col__item-heading">
                        <h4> Digitale Publikation und SEO</h4>
                    </div>
                    <div className="imagelist__col__item-text">
                        <p>Digitale Geschäftsberichte, die nicht nur für den Nutzer wertvoll sind, sondern auch die Online-Sichtbarkeit und das Umweltbewusstsein fördern.</p>
                    </div>
                </div>
            </div>

        </div>
    )
}

export default ImageList