import React, { Component } from 'react';
import { Motion, spring } from 'react-motion';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDown } from '@fortawesome/free-solid-svg-icons';
import './index.scss';
import HolzwegLogoSmall from '../HolzwegLogoSmall';
import banana from '../../assets/ux.banana.png';
import ananas from '../../assets/ux.ananas.png';

class UserExperience extends Component {
  constructor(props) {
    super(props);
  }

  state = {
    isOpen: true,
  };

  defaultHeight = 650;

  fullHeight = null;

  containerRef = null;

  setContainerRef = (ref) => {
    this.containerRef = ref;
  };

  toggleIsOpen = () => this.setState(({ isOpen }) => ({ isOpen: !isOpen }));

  render() {
    const activeHeight = this.state.isOpen
      ? this.containerRef?.scrollHeight || this.defaultHeight
      : this.defaultHeight;

    return (
      <Motion
        defaultStyle={{ x: this.defaultHeight }}
        style={{ x: spring(activeHeight, { damping: 16 }) }}
      >
        {(value) => (
          <div
            className={
              this.state.isOpen
                ? 'user-experience user-experience--opened'
                : 'user-experience'
            }
          >
 <div
              className="user-experience__conteinert"
              ref={this.setContainerRef}
            >
              <div className="user-experience__hw-logo">
                <HolzwegLogoSmall />
              </div>
              <div className="user-experience__headline headline">
                <h2>UX leicht erklärt: <nobr>Ananas</nobr> vs. Banane</h2>
              </div>
              <div className="user-experience__text">
                <p>Für eine optimale <b>User Experience (UX)</b> ist es wichtig, dass sowohl das <b>User Interface (UI)</b> als auch das User Design perfekt aufeinander abgestimmt sind. Um diesen Aspekt besser zu verstehen, wollen wir dir den Unterschied zwischen Ananas und Banane erklären.</p>
                <p>
                  Ein wichtiger Faktor für eine positive User Experience ist das <b>User Interface (UI)</b>. UI bezieht sich auf die visuelle Gestaltung von digitalen Produkten und die Art und Weise, wie der Nutzer mit ihnen interagiert. Ein gut gestaltetes UI trägt maßgeblich dazu bei, dass der Nutzer das Produkt intuitiv und effizient nutzen kann.
                </p>
                <p>
                  Die Banane ist ein perfektes Beispiel für eine <b>optimale UX</b>. Sie ist einfach zu öffnen, hat gute Ernährungswerte und ist nahezu überall auf der Welt erhältlich. Die Schale zeigt uns außerdem an, wann die Banane reif zum Verzehr ist. All diese Faktoren führen zu einer durchweg positiven User Experience.
                </p>
                <p>Die Erwartung an die Frucht, die Nutzung der Frucht (Usability) und die Zufriedenheit ergeben: die Banane hat durchgängig eine sehr gute {' '}<b>User Experience (UX)</b>
                </p>
                <p>Und die Ananas?</p>
                <img src={banana} className="right" />
              </div>
              <div className="user-experience__description">
                <img src={ananas} className="left" />
                <div className="user-experience__description-container">
                  <h3>UX - User Experience:</h3>
                  <strong>
                    Die Gesamtheit der Erfahrung vom Anfang bis zum Ende des
                    Konsums eines Produkts.
                  </strong>
                </div>
              </div>
              <div className="user-experience__text">
                <p>
                  Im Gegensatz dazu ist die Ananas schwieriger zu handhaben und kann eine unangenehme Erfahrung sein. Sie ist schwer zu öffnen und kann eine ziemliche Sauerei verursachen. Selbst wenn sie besser schmeckt als eine Banane, kann sie aufgrund dieser Faktoren eine schlechtere User Experience bieten.
                </p>
                <p>Um eine optimale UX zu gewährleisten, <b>müssen UI und User Design perfekt aufeinander abgestimmt sein.</b> Wenn du professionelles Webdesign benötigst, das auf UX, UI und User Design optimiert ist, dann bist du bei uns genau richtig. Wir bieten maßgeschneiderte Lösungen für deine individuellen Bedürfnisse. <b>Kontaktiere uns noch heute!</b></p>
              </div>
            </div>
          </div>
        )}
      </Motion>
    );
  }
}

export default UserExperience;
