import React from 'react'
import './index.scss'

const CallUsCard = ({ name, competence, phone, email, image, funImage, shownPhone, text }) => (
  <div className="call-us-card">
    <div className="call-us-card__image" style={ {backgroundImage: `url(${image})`} }></div>
    <div className="call-us-card__text">
      <span className="call-us-card__name">{name}</span>
      <span className="call-us-card__competence">{competence}</span>
      <p>{text}</p>
      <a href={`tel:${phone}`} className="call-us-card__phone">
        {shownPhone}
      </a>
      <a href={`mailto:${email}`} className="call-us-card__email">
        {email}
      </a>
    </div>
  </div>
)

export default CallUsCard
