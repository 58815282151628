import React from 'react'
import './index.scss'

const ArrowButton = ({ full, mods = [], onClick = () => {} }) => (
  <button className={`arrow-button ${mods.map(m => `arrow-button--${m}`).join(' ')}`} onClick={onClick}>
    {
      full
        ? <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 50 50"><path d="M25,0A25,25,0,1,1,0,25,25,25,0,0,1,25,0Zm4,18-.83-.84a.52.52,0,0,0-.39-.17.52.52,0,0,0-.38.17L19.56,25a.55.55,0,0,0-.16.39.55.55,0,0,0,.16.38l7.81,7.8a.49.49,0,0,0,.38.17.52.52,0,0,0,.39-.17l.83-.83A.52.52,0,0,0,29,32l0,0-6.58-6.58L29,18.78a.51.51,0,0,0,.05-.73Z"/></svg>
        : <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 50 50"><path d="M25,50A25,25,0,0,1,0,25,24.94,24.94,0,0,1,24.88,0H25a25,25,0,0,1,0,50ZM24.88,2.2a22.86,22.86,0,1,0,.12,0Z"/><path d="M29,18l-.8-.8a.52.52,0,0,0-.4-.2.76.76,0,0,0-.4.2L19.6,25a.52.52,0,0,0-.2.4.76.76,0,0,0,.2.4l7.8,7.8a.52.52,0,0,0,.4.2.76.76,0,0,0,.4-.2l.8-.8a.49.49,0,0,0,0-.68l0,0h0l-6.6-6.6L29,18.9A.75.75,0,0,0,29,18Z"/></svg>
    }
  </button>
)

export default ArrowButton
