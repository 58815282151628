import React from 'react'
import Link from 'gatsby-link'
import './index.scss'

import backgroundImage from '../../assets/software.stack.balance.jpg'

const SoftwareStack = () => (
  <div
    className="software-stack"
    style={{ backgroundImage: `url(${backgroundImage})` }}
  >
    <div className="software-stack__container">
      <div className="software-stack__title">
        PIM, DAM, Docker, ERP und SASS?
      </div>
      <div className="software-stack__content">
      Dabei handelt es sich um gängige Technologien, mit denen unsere Webagentur täglich arbeitet. Unsere erfahrenen Experten setzen diese Technologien ein, um individuelle Software- und Datenbanksysteme zu entwickeln, die den Bedürfnissen und Anforderungen unserer Kund:innen entsprechen. Wir sind spezialisiert auf die Implementierung von PIM- und DAM-Systemen sowie Docker, ERP und SASS. Kontaktieren Sie uns jetzt, um mehr darüber zu erfahren, wie wir Ihnen helfen können, Ihr Geschäft zu optimieren und effizienter zu gestalten.
      </div>
      <div className="software-stack__button-wrapper">
        <div className="software-stack__button">
          <Link to={'/technologie'} className="button button--color-black">
            Mehr zur Technologie
          </Link>
        </div>
        <div className="software-stack__button">
          <Link to={'/kontakt'} className="button button--color-black">
            Lieber gleich zum Kontakt
          </Link>
        </div>
      </div>
    </div>
  </div>
)

export default SoftwareStack