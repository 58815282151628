import React, { Component } from 'react'
import Swiper from 'react-id-swiper/lib/custom'
import 'react-id-swiper/src/styles/scss/swiper.scss'
import { Link } from 'gatsby'

import './index.scss'
import ArrowButton from '../ArrowButton'
import HoverCubeAnimation from '../HoverCubeAnimation'

import { exposeSliderMixin } from '../../utils'

class ProjectSlider extends exposeSliderMixin(Component) {
  render() {
    const options = {
      slidesPerView: 'auto',
      freeMode: true,
      centeredSlides: false,
      spaceBetween: 12,
      breakpoints: {
        1300: {
          slidesPerView: 3
        },
        1000: {
          slidesPerView: 2
        },
        630: {
          slidesPerView: 1
        }
      }
    }

    return (
      <div className="project-slider" onTransitionEnd={this.handleDrag}>
        <Swiper {...options} ref={this.onInit}>
          {this.props.projects.map((slide, outerIndex) => (
            <div className="swiper-slide" key={outerIndex}>
              {slide.images.map((project, innerIndex) => (
                <div
                  key={'' + outerIndex + innerIndex}
                  style={{ display: 'block' }}
                  className="project-slider__item"
                >
                  <HoverCubeAnimation
                    key={innerIndex}
                    speed={600}
                    front={
                      <div
                        className="project-slider__image"
                        style={{
                          backgroundImage: `url(${project.image})`
                        }}
                      />
                    }
                    back={
                      <>
                        {project.link && !project.link.match(/^https?/) && (
                          <Link
                            to={project.link || ''}
                            className="project-slider__text"
                          >
                            {project.text}
                          </Link>
                        )}
                        {project.link && project.link.match(/^https?/) && (
                          <a
                            href={project.link}
                            target="_blank"
                            className="project-slider__text"
                          >
                            {project.text}
                          </a>
                        )}
                        {!project.link && (
                          <div className="project-slider__text">
                            {project.text}
                          </div>
                        )}
                      </>
                    }
                  />
                </div>
              ))}
            </div>
          ))}
        </Swiper>
        <div className="project-slider__bottom">
          <div className="ref">
            {' '}
            <a
              className="button button--color-black button--color-black--ref"
              href="/projekte"
            >
              {' '}
              Alle Projekte
            </a>
          </div>
          <div className="project-slider__button-wrapper">
            <ArrowButton
              color="blue"
              full
              mods={[...this.getModsLeft(), 'color-blue']}
              onClick={this.prevSlide}
            />
            <ArrowButton
              full
              mods={[...this.getModsRight(), 'color-blue']}
              onClick={this.nextSlide}
            />
          </div>
        </div>
      </div>
    )
  }
}
export default ProjectSlider
