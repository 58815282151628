import React, { Component } from 'react'
import Swiper from 'react-id-swiper/lib/custom'
import 'react-id-swiper/src/styles/scss/swiper.scss'
import './index.scss'
import ArrowButton from '../ArrowButton'
import { exposeSliderMixin } from '../../utils'

class ImageSlider extends exposeSliderMixin(Component) {
  render() {
    const options = {
      freeMode: false,
      centeredSlides: true,
      spaceBetween: 50
    }

    return (
      <div className="image-slider" onTransitionEnd={this.handleDrag}>
        <Swiper {...options} ref={this.onInit}>
          {this.props.images.map(image => (
            <div key={image.image} style={{ textAlign: 'center' }}>
              <img
                src={image.image}
                style={{ margin: 'auto' }}
                alt={image.alt}
              />
            </div>
          ))}
        </Swiper>
        <div className="image-slider__bottom">
          <div className="image-slider__button-wrapper">
            <ArrowButton mods={this.getModsLeft()} onClick={this.prevSlide} />
            <ArrowButton mods={this.getModsRight()} onClick={this.nextSlide} />
          </div>
        </div>
      </div>
    )
  }
}

export default ImageSlider
