import React, { useState, useEffect } from 'react';
import './index.scss';

function HeaderSlider({ slides, fixed }) {
    const [startX, setStartX] = useState(null);
    const [moving, setMoving] = useState(false);
    const [active, setActive] = useState(0);
    const [autoplay, setAutoplay] = useState(true);
    const max = slides.length;
    const interval = 3500;
    let slideInterval;

    useEffect(() => {
        if (autoplay) {
            startInterval();
        }
        return () => clearInterval(slideInterval);
    }, [active, autoplay]);

    const startInterval = () => {
        clearInterval(slideInterval);
        slideInterval = setInterval(() => {
            setActive((prevActive) => (prevActive + 1) % max);
        }, interval);
    };

    const handleTouchStart = (e) => {
        setStartX(e.touches[0].clientX);
        setMoving(true);
        setAutoplay(false);
    };

    const handleTouchMove = (e) => {
        if (moving) {
            const currentX = e.touches[0].clientX;
            const diff = startX - currentX;

            if (diff > 50) {
                nextOne();
                setMoving(false);
            } else if (diff < -50) {
                prevOne();
                setMoving(false);
            }
        }
    };

    const handleTouchEnd = () => {
        setStartX(null);
        setMoving(false);
    };

    const nextOne = () => {
        setActive((prevActive) => (prevActive + 1) % max);
    };

    const prevOne = () => {
        setActive((prevActive) => (prevActive - 1 + max) % max);
    };

    const handleDotClick = (index) => {
        setActive(index);
        setAutoplay(false);
    };

    return (
        <section
            className='header-slider'
            id='slider'
            onTouchStart={handleTouchStart}
            onTouchMove={handleTouchMove}
            onTouchEnd={handleTouchEnd}
            style={{ height: fixed ? '400px' : 'auto' , width: fixed ? '500px' : ''}}
        >
            <div className='wrapper' style={{ transition: '.5s all linear', willChange: 'transform' }}>
                {slides.map((item, index) => {
                    return (
                        <div className={`each-slide ${active === index ? 'active' : ''}`} key={index}>
                            <img src={item.eachSlide} className='image' alt={item.alt} />
                        </div>
                    );
                })}
            </div>
            <div className='button__wrapper'>
                <button type='button' className='arrows prev' onClick={prevOne}>
                    <svg fill='#000' width='50' height='50' viewBox='0 0 24 24'>
                        <path d='M15.41 7.41L14 6l-6 6 6 6 1.41-1.41L10.83 12z' />
                        <path d='M0 0h24v24H0z' fill='none' />
                    </svg>
                </button>
                <button type='button' className='arrows next' onClick={nextOne}>
                    <svg fill='#000' height='50' viewBox='0 0 24 24' width='50'>
                        <path d='M10 6L8.59 7.41 13.17 12l-4.58 4.59L10 18l6-6z' />
                        <path d='M0 0h24v24H0z' fill='none' />
                    </svg>
                </button>
            </div>
            <ul className='dots-container'>
                {slides.map((slide, index) => (
                    <li className={active === index ? 'active dots' : 'dots'} key={index}>
                        <button onClick={() => handleDotClick(index)}>
                            <span>&#9679;</span>
                        </button>
                    </li>
                ))}
            </ul>
        </section>
    );
}

export default HeaderSlider;