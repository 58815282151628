import React from 'react'
import Link from 'gatsby-link'
import './index.scss'

const ProjectPagination = ({
  titleLeft,
  imageLeft,
  leftLink,
  titleRight,
  imageRight,
  rightLink,
  titleModsRight,
  titleModsLeft
}) => {
  return (
    <div className="project-pagination">
      <div
        className="project-pagination__left"
        style={{ backgroundImage: `url(${imageLeft})` }}
      >
        <div
          className={
            titleModsLeft !== undefined
              ? `project-pagination__text project-pagination__text--right ${titleModsLeft
                  .map(m => m)
                  .join(' ')}`
              : 'project-pagination__text project-pagination__text--right'
          }
        >
          <p>Vorheriges Projekt</p>
          <h2>{titleLeft}</h2>
        </div>
        <Link to={leftLink} />
      </div>
      <div
        className="project-pagination__right"
        style={{ backgroundImage: `url(${imageRight})` }}
      >
        <div className="project-pagination__text">
          <div
            className={
              titleModsRight !== undefined
                ? `project-pagination__text ${titleModsRight
                    .map(m => m)
                    .join(' ')}`
                : 'project-pagination__text '
            }
          >
            <p>Nächstes Projekt</p>
            <h2>{titleRight}</h2>
          </div>
        </div>
        <Link to={rightLink} />
      </div>
    </div>
  )
}

export default ProjectPagination
