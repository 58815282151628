import React from 'react'
import Link from 'gatsby-link'
import './index.scss'

const TextBox = ({
  title,
  subTitle,
  text,
  afterText,
  image,
  titleMods,
  buttons,
  align,
  id,
  isMulticolor,
  isMulticolorHead,
  textalign,
  buttonalign,
  status
}) => {
  let ampelClass = 'karriere__indicator';
  let ampelText = '';

  if (status === 'now') {
    ampelClass += ' karriere__indicator-green';
    ampelText = 'AB SOFORT';
  } else if (status === 'initiative') {
    ampelClass += ' karriere__indicator-orange';
    ampelText = 'INITIATIVE ERWÜNSCHT';
  }
  return (
    <div {...(id && { id })} className={`text-box ${align ? align : ''} ${isMulticolor ? 'multi-color' : ''} ${isMulticolorHead ? 'multi-color-head' : ''}`}>
      {status !== undefined ? (
        <div className={ampelClass}><a href="mailto:bewerbung@holzweg.com">{ampelText}</a></div>
      ) : ''}
      {title.length !== 0 ? (
        titleMods !== undefined ? (
          <h2
            className={`text-box__title ${titleMods
              .map(mod => `text-box__title--${mod}`)
              .join(' ')}`}
          >
            {title}
          </h2>
        ) : (
          <h2 className="text-box__title">{title}</h2>
        )
      ) : (
        ''
      )}
      {
        subTitle
          ? <div className="text-box__subtitle">{subTitle}</div>
          : <div />
      }
      <div className={`text-box__content ${align ? align : ''}`}>
        <div className={`text-box__text ${textalign ? textalign : ''}`}>{text}</div>
        {afterText}
        <img src={image} />
      </div>
      <div className={`text-box__button-wrapper ${buttonalign ? buttonalign : ''}`}>
        {buttons && buttons.map(({ link, text, color, type }, index) => (
          type !== "cta-round" ? (
            link.includes('http') ? (
              <a href={link} target="_blank" className={`button button--color-${color ? color : 'black'}`} key={index}>
                {text}
              </a>
            ) : (
              <Link to={link} className={`button button--color-${color ? color : 'black'}`} key={index}>
                {text}
              </Link>
            )
          ) : (
            <button className="text-box__cta-round cta-round">
              <span className='cta-round__link-container'>
                <Link to={link}>
                  {text}
                </Link>
              </span>
              <svg viewBox="0 0 13 10" height="10px" width="15px">
                <path d="M1,5 L11,5"></path>
                <polyline points="8 1 12 5 8 9"></polyline>
              </svg>
            </button>
          )
        ))}
      </div>
    </div>
  )
}

export default TextBox
