import React from 'react'
import './index.scss'
import BlogOverviewItem from './BlogOverviewItem'

const BlogOverview = ({ blogs, title }) => {
  return (
    <div className="blog-overview">
      <div className="blog-overview__headline">
        {title}
      </div>
      <div className="blog-overview__container">
        {
          blogs.map((blog, index) => (
            <BlogOverviewItem blog={blog} key={index}/>
          ))
        }
      </div>
    </div>
  )
}

export default BlogOverview
