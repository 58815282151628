import React from 'react'
import Link from 'gatsby-link'

import ScrollPercentage from 'react-scroll-percentage'

const HoverSection = ({
  header,
  text,
  link,
  linkText,
  isActive,
  modifier,
  onMouseEnter,
  image
}) => (
  <section
    className={`banner-full__section banner-full__section--${modifier} ${
      isActive ? 'banner-full__section--active' : ''
    }`}
    onMouseEnter={onMouseEnter}
  >
    <div className="banner-full__content-wrapper">
      <h2>{header}</h2>
      <div className="banner-full__content">
        <p>{text}</p>
        <Link to={link} className="button">
          {linkText}
        </Link>
      </div>
    </div>
    <ScrollPercentage>
      {( { percentage } ) => (
        <div
          className="banner-full__background"
          style={{
            backgroundImage: `url(${image})`,
            display: isActive ? 'block' : 'none',
            backgroundPositionY: `${percentage * 600}px`
          }}
        />
      )}
    </ScrollPercentage>
  </section>
)

export default HoverSection
