import React, { Component } from 'react'
import Swiper from 'react-id-swiper/lib/custom'
import 'react-id-swiper/src/styles/scss/swiper.scss'
import Link from 'gatsby-link'
import './index.scss'
import ArrowButton from '../ArrowButton'
import { scrollContainerMixin, exposeSliderMixin } from '../../utils'

class BlogSlider extends exposeSliderMixin(scrollContainerMixin(Component)) {
  render() {
    const options = {
      loop: false,
      slidesPerView: this.props.singleRow ? 2 : 4,
      freeMode: false,
      centeredSlides: false,
      spaceBetween: 0,
      touch: true,
      breakpoints: {
        1300: {
          slidesPerView: this.props.singleRow ? 2 : 3
        },
        1000: {
          slidesPerView: this.props.singleRow ? 2 : 2
        },
        630: {
          slidesPerView: 1
        }
      }
    }

    const sliderItemGroup = this.props.cards.reduce(
      (result, value, index, array) => {
        if (index % 2 === 0) result.push(array.slice(index, index + 2))
        return result
      },
      []
    )

    return (
      <div ref={this.setScrollContainerRef} className="blog-slider">
        {this.props.title && (
          <div className="blog-slider__headline headline">
            <h2>{this.props.title}</h2>
          </div>
        )}
        <div
          className="blog-slider__container"
          onTransitionEnd={this.handleDrag}
        >
          <Swiper {...options} ref={this.onInit}>
            {sliderItemGroup.map((item, outerIndex) => (
              <div
                className={`swiper-slide ${
                  this.props.singleRow ? 'swiper-slide--single-row' : ''
                }`}
                key={outerIndex}
              >
                {item.map((item, innerIndex) => (
                  <div
                    className={`blog-slider__item
                      ${item.whiteColor ? 'blog-slider__item--white' : ''}
                      ${item.blackColor ? 'blog-slider__item--black' : ''}`}
                    key={innerIndex}
                  >
                    <div
                      className="blog-slider__background"
                      style={{
                        backgroundImage: item.backgroundImage
                          ? `url(${item.backgroundImage})`
                          : ''
                      }}
                    />
                    <div className="blog-slider__tag">{item.tag}</div>
                    <div className="blog-slider__headline">
                      <h3>{item.headline}</h3>
                    </div>
                    <div className="blog-slider__subheadline">
                      <h4>{item.subheadline}</h4>
                    </div>
                    {item.readingTime && (
                      <div className="blog-slider__reading-time">
                        {item.readingTime} Min. lesen
                      </div>
                    )}
                    {item.bottomText && (
                      <div className="blog-slider__reading-time">
                        {item.bottomText}
                      </div>
                    )}
                    <Link
                      to={item.link ? item.link : ''}
                      className="blog-slider__link"
                    />
                  </div>
                ))}
              </div>
            ))}
          </Swiper>
        </div>
        <div className="blog-slider__bottom">
          <div className="blog-slider__button-wrapper">
            <ArrowButton
              full
              mods={[...this.getModsLeft(), 'color-orange']}
              onClick={this.prevSlide}
            />
            <ArrowButton
              full
              mods={[...this.getModsRight(), 'color-orange']}
              onClick={this.nextSlide}
            />
          </div>
          <a class="button button--color-black" href="/blog">Alle Beiträge</a>
        </div>
      </div>
    )
  }
}

export default BlogSlider
