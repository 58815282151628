import React, { useEffect, useState, useRef } from 'react'
import './index.scss'

const arrows = [
  { title: 'Akquisition', color: '#FFA500', text: 'Die Akquisition von potenziellen Kund:innen ist ein wichtiger Schritt bei der Entwicklung einer Strategie. Mit gezielten Zielgruppenanalysen und effektiven Marketingaktivitäten können Sie Ihre Reichweite erhöhen und neue Kund:innen gewinnen. Dies hilft, Ihre Marke bekannt zu machen und Ihre Umsätze zu steigern.' },
  { title: 'Orientierung', color: '#0072C6', text: 'Eine klare Orientierung ist ein wichtiger Aspekt bei der Strategieentwicklung. Eine klare Positionierung und die Identifikation der Bedürfnisse Ihrer Zielgruppe können Ihnen dabei helfen, eine Strategie zu entwickeln, die auf Ihre Ziele ausgerichtet ist. Durch eine klare Orientierung können Sie Ihre Zielgruppe besser ansprechen und langfristige Beziehungen aufbauen.' },
  { title: 'Navigation', color: '#00A98F', text: 'Eine effektive Navigation ist entscheidend für den Erfolg Ihrer Website bzw. der Usability Ihrer individuellen Softwarelösung und sollte bei der Strategieentwicklung berücksichtigt werden. Eine gut durchdachte Navigation hilft Benutzer:innen, schnell und einfach die gewünschten Inhalte zu finden. Eine einfache und intuitive Navigation kann die Benutzerfreundlichkeit Ihrer Website verbessern und zu einer höheren Conversion-Rate führen.' },
  { title: 'Information', color: '#8A2BE2', text: 'Die Bereitstellung relevanter Informationen ist eine wichtige Komponente jeder Strategie. Durch eine gründliche Recherche und Analyse Ihrer Zielgruppe sowie die Erstellung von qualitativ hochwertigen Inhalten können Sie das Interesse Ihrer Zielgruppe wecken und sie informieren. Durch die Bereitstellung relevanter Informationen können Sie das Vertrauen Ihrer Zielgruppe gewinnen und sich als Experte auf Ihrem Gebiet positionieren.' },
  { title: 'Aktion', color: '#FF4136', text: 'Eine Strategie sollte darauf abzielen, dass Ihre Zielgruppe eine bestimmte Aktion ausführt, wie beispielsweise den Kauf eines Produkts oder die Kontaktaufnahme mit Ihnen. Eine klare Handlungsaufforderung und ein effektiver Call-to-Action können Ihnen dabei helfen, Ihre Ziele zu erreichen. Eine gut platzierte Handlungsaufforderung kann Besuchern Ihrer Website helfen, schnell und einfach eine Aktion auszuführen.' },
  { title: 'Loyalität', color: '#9B9B9B', text: 'Die Schaffung von Loyalität ist ein wichtiger Bestandteil jeder Strategie. Indem Sie eine langfristige Beziehung zu Ihren Kund:innen aufbauen und ihre Bedürfnisse erfüllen, können Sie die Loyalität Ihrer Kund:innen gewinnen und langfristigen Erfolg sichern. Durch die Schaffung von Loyalität können Sie das Vertrauen Ihrer Kund:innen gewinnen und sie dazu ermutigen, wiederholt bei Ihnen einzukaufen und Ihre Marke weiterzuempfehlen.' }
]
const CustomerJourney = () => {

  const containerRef = useRef(null);
  const intervalRef = useRef(null);
  let i = 0;
  const delay = 30;
  const scrollIncrement = 0.5;
  const [manualScroll, setManualScroll] = useState(false);

  useEffect(() => {
    if (containerRef.current && !manualScroll) {
      intervalRef.current = setInterval(() => {
        containerRef.current.scrollTo(i, 0);
        i += scrollIncrement;
        if (i >= containerRef.current.scrollWidth) {
          i = 0;
          clearInterval(intervalRef.current);
          setTimeout(() => {
            intervalRef.current = setInterval(() => {
              containerRef.current.scrollTo(i, 0);
              i += scrollIncrement;
              if (i >= containerRef.current.scrollWidth) {
                i = 0;
              }
            }, delay);
          }, delay);
        }
      }, delay);
    }
    return () => {
      clearInterval(intervalRef.current);
    };
  }, [manualScroll, i]);


  const handleMouseOver = () => {
    setManualScroll(true);
    clearInterval(intervalRef.current);
  };

  const handleClick = () => {
    setManualScroll(true);
    clearInterval(intervalRef.current);
  }

  return (
    <div className='arrow-wrapper'>
      <div className="arrow-container__title">Customer Journey</div>
      <div className='arrow-container' onMouseOver={handleMouseOver} onTouchMove={handleClick} ref={containerRef}>
        {arrows.map(({ color, title }, index) => (
          <React.Fragment key={index}>
            <span className='arrow-before' style={{ borderRightColor: color }}></span>
            <div className="arrow" style={{ backgroundColor: color }}>
              <div className="arrow-text">
                {`${index + 1}. ${title}`}
              </div>
            </div>
            <span className='arrow-after' style={{ borderTopColor: color, borderBottomColor: color, borderLeftColor: color, }}></span>
          </React.Fragment>
        ))}

      </div>
      <div className='arrow-container__text'>
        {arrows.map(({ title, text }, index) => (
          <div key={index}>
            <span>
              <h3>{`${index + 1}. ${title}`}</h3>
              <p>{text}</p>
              <br />
            </span>
          </div>
        ))}
      </div>
    </div>
  )
}

export default CustomerJourney
