import React, { Component } from 'react'
import { TransitionGroup, CSSTransition } from 'react-transition-group'
import './index.scss'
import Link from 'gatsby-link'

class Brands extends Component {
  constructor (props) {
    super(props)
    this._isMounted = false
  }
  state = {
    nextBrand: 4,
    nextUpdateIndex: 0,
    activeBrands: this.props.brands.slice(0, 4)
  }

  intervalHandle = null

  toggleNextBrand = () =>
      this._isMounted && this.setState(({ nextBrand, nextUpdateIndex, activeBrands }) => ({
      nextBrand: (nextBrand + 1) % this.props.brands.length,
      nextUpdateIndex: (nextUpdateIndex + 1) % 4,
      activeBrands: [
        ...activeBrands.slice(0, nextUpdateIndex),
        this.props.brands[nextBrand],
        ...activeBrands.slice(nextUpdateIndex + 1, 4)
      ]
    }))

  componentDidMount() {
    this._isMounted = true
    this.intervalHandle = setInterval(() => {
      Array(4)
        .fill()
        .forEach(() => setTimeout(this.toggleNextBrand, Math.random() * 200))
    }, 2000)
  }

  componentWillUnmount() {
    this._isMounted = false
    clearInterval(this.intervalHandle)
  }

  render = () => (
    <div className="brands">
    {
      this.props.title &&
      <div className="brands__headline">
        <h2>{this.props.title}</h2>
      </div>
    }
      <div className="brands__container">
        {this.state.activeBrands.map((brand, i) => (
          <div className="brands__column" key={i}>
            <TransitionGroup className="brands__wrapper">
              <CSSTransition
                key={brand}
                timeout={2000}
                classNames="brands__item-"
              >
                <Link className="brands_item" to={brand.link} >
                  <img className="brands__item" src={brand.image} alt={brand.alt} />
                </Link>
              </CSSTransition>
            </TransitionGroup>
          </div>
        ))}
      </div>
    </div>
  )
}

export default Brands
