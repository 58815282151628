import React, { Component } from 'react'
import ScrollPercentage from 'react-scroll-percentage'

export default class ShowElement extends Component {
  state = {
    showElement: false
  }

  handleChange = (percentage, inView) => {
    this.setState(({ showElement }) => ({
      showElement: showElement
        ? !(percentage === 0 && inView)
        : percentage > this.props.threshold
    }))
  }

  render = () => (
    <ScrollPercentage
      className={this.props.className}
      onChange={this.handleChange}
    >
      {this.props.children(this.state.showElement)}
    </ScrollPercentage>
  )
}
