import React, { Component } from 'react'
import './index.scss'

class HoverCubeAnimation extends Component {
  state = {
    height: 0
  }

  updateHeight() {
    this.setState(() => ({
      height: this.cube.clientHeight || this.cube.offsetHeight
    }))
  }

  componentDidMount() {
    this.updateHeight()
  }

  render() {
    return (
      <div className="cube__wrapper">
        <div
          className="cube"
          ref={cube => {
            this.cube = cube
          }}
          style={{
            transition: `transform ${this.props.speed || 300}ms`
          }}
        >
          <div
            style={{
              transform: `translateZ(${this.state.height / 2}px)`,
              msTransform: `translateZ(${this.state.height / 2}px)`
            }}
          >
            {this.props.front}
          </div>
          <div
            style={{
              transform: `rotateX(-90deg) translateZ(-${this.state.height /
                2}px)`,
              msTransform: `rotateX(-90deg) translateZ(-${this.state.height /
                2}px)`
            }}
          >
            {this.props.back}
          </div>
        </div>
      </div>
    )
  }
}

export default HoverCubeAnimation
