import React from "react";
import './index.scss'

const LocalSvg = ({ fill, link }) => (
    <a href={link}>
    <svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 384 512" fill={fill}>
        <path d="M215.7 499.2C267 435 384 279.4 384 192C384 86 298 0 192 0S0 86 0 192c0 87.4 117 243 168.3 307.2c12.3 15.3 35.1 15.3 47.4 0zM192 128a64 64 0 1 1 0 128 64 64 0 1 1 0-128z" />
        </svg>
    </a>
);

const UserSvg = ({ fill, link }) => (
    <a href={link}>
    <svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 640 512" fill={fill}>
        <path d="M144 0a80 80 0 1 1 0 160A80 80 0 1 1 144 0zM512 0a80 80 0 1 1 0 160A80 80 0 1 1 512 0zM0 298.7C0 239.8 47.8 192 106.7 192h42.7c15.9 0 31 3.5 44.6 9.7c-1.3 7.2-1.9 14.7-1.9 22.3c0 38.2 16.8 72.5 43.3 96c-.2 0-.4 0-.7 0H21.3C9.6 320 0 310.4 0 298.7zM405.3 320c-.2 0-.4 0-.7 0c26.6-23.5 43.3-57.8 43.3-96c0-7.6-.7-15-1.9-22.3c13.6-6.3 28.7-9.7 44.6-9.7h42.7C592.2 192 640 239.8 640 298.7c0 11.8-9.6 21.3-21.3 21.3H405.3zM224 224a96 96 0 1 1 192 0 96 96 0 1 1 -192 0zM128 485.3C128 411.7 187.7 352 261.3 352H378.7C452.3 352 512 411.7 512 485.3c0 14.7-11.9 26.7-26.7 26.7H154.7c-14.7 0-26.7-11.9-26.7-26.7z" />
        </svg>
    </a>
);

const BranchSvg = ({ fill, link }) => (
    <a href={link}>
    <svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 448 512" fill={fill}>
        <path d="M80 104a24 24 0 1 0 0-48 24 24 0 1 0 0 48zm80-24c0 32.8-19.7 61-48 73.3v87.8c18.8-10.9 40.7-17.1 64-17.1h96c35.3 0 64-28.7 64-64v-6.7C307.7 141 288 112.8 288 80c0-44.2 35.8-80 80-80s80 35.8 80 80c0 32.8-19.7 61-48 73.3V160c0 70.7-57.3 128-128 128H176c-35.3 0-64 28.7-64 64v6.7c28.3 12.3 48 40.5 48 73.3c0 44.2-35.8 80-80 80s-80-35.8-80-80c0-32.8 19.7-61 48-73.3V352 153.3C19.7 141 0 112.8 0 80C0 35.8 35.8 0 80 0s80 35.8 80 80zm232 0a24 24 0 1 0 -48 0 24 24 0 1 0 48 0zM80 456a24 24 0 1 0 0-48 24 24 0 1 0 0 48z" />
        </svg>
    </a>
);

const gridItems = [
    { title: "Lokaler Ansprechpartner", text: "Profitieren Sie von unserer persönlichen Betreuung, der Ihnen jederzeit mit kompetentem Rat und schneller Hilfe zur Seite steht.", image: "local", color: "#ffa07a", link:"https://www.holzweg.com/kontakt" },
    { title: "Verschiedenste Kunden", text: "Vertrauen Sie auf die Zufriedenheit einer breiten Palette erfolgreicher Partnerschaften mit Unternehmen aus verschiedensten Branchen.", image: "user", color: "#F08080", link:"https://www.holzweg.com/projekte" },
    { title: "25 Jahre Erfahrung", text: "Setzen Sie auf einen zuverlässigen Partner mit über 25 Jahren Erfahrung. Unsere fundiertes Know-how stehen für nachhaltige Lösungen und exzellenten Service.", image: "branch", color: "#FFB6C1", link:"https://www.holzweg.com/internetagentur" },
];

const GridComponent = () => {
    return (
        <div className="gridcomponent">
            <h2>Warum holzweg?</h2>
            <div className="gridcomponent__container">
                {gridItems.map((item, index) => (
                    <div className="gridcomponent__item" key={index}>
                        <span className="gridcomponent__item-img">
                            {item.image === "local" && <LocalSvg fill={item.color} link={item.link} />}
                            {item.image === "user" && <UserSvg fill={item.color} link={item.link} />}
                            {item.image === "branch" && <BranchSvg fill={item.color} link={item.link} />}
                        </span>
                        <span className="gridcomponent__item-title">{item.title}</span>
                        <span className="gridcomponent__item-text">{item.text}</span>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default GridComponent;
