import React from 'react'
import './index.scss'
import Link from 'gatsby-link'

const AppointmentIndividual = ({noImage, image}) => (
  <div className="appointmentindividual">
    <div className="appointmentindividual__container">
      <span>Sie benötigen eine</span>
      <h2>Individual-Software?</h2>
      <Link to={'/individual-software'} className="button">
        Mehr erfahren
      </Link>
      {
        noImage
        ? ''
        : <img src={image} />
      }
    </div>
  </div>
)

export default AppointmentIndividual