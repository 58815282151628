import React from 'react'
import Link from 'gatsby-link'
import './index.scss'

const BannerBox = ({ text, image, link, linkText, singleImage }) => {
  return (
    <div className="banner-links__box">
      <div className="banner-links__box-overlay">
        <div className="banner-links__box-text">
          <h2>{text}</h2>
          <Link to={link} className="button">
            {linkText}
          </Link>
        </div>
      </div>
      <div
        className="banner-links__box-background"
        style={{
          backgroundImage: `url(${image})`,
          display: `${singleImage ? 'none' : 'block'}`
        }}
      />
    </div>
  )
}
export default BannerBox
