import React, { forwardRef } from 'react'
import { SharedBackground } from '../../utils'
import background from '../../assets/4erBoxen.bg.jpg'
import backgroundHover from '../../assets/4erBoxen.blue.bg.jpg'

import './index.scss'

export const QuickNavigationBox = forwardRef(
  ({ link, heading, subtext, ...passProps }, ref) => {
    const Tag = link ? 'a' : 'div'
    return (
      <Tag
        {...passProps}
        className="quick-navigation__item"
        ref={ref}
        href={link}
        target="_blank"
      >
        <div className="quick-navigation__text">
          <h2>{heading}</h2>
          <h3>{subtext}</h3>
        </div>
      </Tag>
    )
  }
)

export const QuickNavigation = ({ bgColor, title, text, bottomText, children }) => (
  <div
    className="quick-navigation"
    style={{ backgroundColor: `${bgColor ? bgColor : 'white'}` }}
  >
    {title && <h3>{title}</h3> }
    {text && <p>{text}</p> }
    <SharedBackground
      className="quick-navigation__container"
      backgroundImage={`url(${background})`}
      hoverBackgroundImage={`url(${backgroundHover})`}
    >
      { children }
    </SharedBackground>
    {bottomText && <p>{bottomText}</p>}
  </div>
)

export default QuickNavigation
