import React from 'react'
import Link from 'gatsby-link'
import './index.scss'

const BlogOverviewItem = ({ blog }) => {
  const tagStyle = () => {
    switch (blog.tag) {
      case 'Technologie':
        return { color: '#2b2ba5' }
      case 'ui':
        return { color: 'var(--secondary-color)' }
        case 'ux':
          return { color: 'var(--secondary-color)' }
      case 'Karriere':
        return { color: '#1e940e' }
      default:
        return {}
    }
  }

  const tagText = () => {
    switch (blog.tag) {
      case 'Technologie':
        return 'Technologie'
      case 'ui':
        return 'UI - User Interface'
      case 'ux':
        return 'UX - User Experience'
      default:
        return blog.tag
    }
  }
  return (
    <div className="blog-overview-item">
      <div className="blog-overview-item__container">
        {blog.backgroundImage ? (
          <div className="blog-overview-item__image">
            <img src={blog.backgroundImage} />
          </div>
        ) : (
          <div className="blog-overview-item__image-placeholder"></div>
        )}
        <div className="blog-overview-item__content">
          <span className="blog-overview-item__content__tag" style={tagStyle()}>{tagText()}</span>
          <h3>{blog.headline}</h3>
          <p className='blog-overview-item__content__subheading'>{blog.subheadline}</p>
          {blog.readingTime ? (
            <div className="blog-overview-item__reading-time">
              {blog.readingTime} Min. lesen
            </div>
          ) : (
            ''
          )}
        </div>
      </div>
      <Link to={blog.link} />
    </div>
  )
}

export default BlogOverviewItem
