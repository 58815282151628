import React, { Component } from 'react';
import ScrollPercentage from 'react-scroll-percentage';
import './index.scss';

class BannerSmall extends Component {
  constructor(props) {
    super(props);

    this.state = {
      scrollPercentage: 0,
    };

    this.wrapper = React.createRef();
  }

  componentDidMount() {
    const vh = global.parent ? global.parent.innerHeight : global.innerHeight || 0;

    const bounds = this.wrapper.current.getBoundingClientRect();
    const scrollPercentage =
      1 -
      Math.max(
        0,
        Math.min(1, (bounds.bottom + window.pageYOffset) / (vh + bounds.height))
      );

    this.setState({
      scrollPercentage,
    });
  }

  render() {
    const { image, overlay, title, text, align, parallaxOFF, style } = this.props;
    const { scrollPercentage } = this.state;

    return (
      <ScrollPercentage>
        {({ percentage }) => (
          <div
            className={`banner-small ${align ? align : ''}`}
            style={{
              backgroundImage: `url(${image})`,
              backgroundSize: 'cover',
              backgroundPosition: 'center center',
              backgroundPositionY: parallaxOFF
                ? 'center'
                : `${(percentage - scrollPercentage) * 250}px`,
              ...style,
            }}
            ref={this.wrapper}
          >
            {overlay ? <div className="banner-small__overlay" /> : ''}
            <div className="banner-small__filter-overlay"
              style={
                {
                  backgroundColor: this.props.tooBright ? 'rgba(0,0,0,0.4)' : '',
                }
              } />
            <div className="banner-small__container">
              <h1>{title}</h1>
              {text && <p>{text}</p>}
            </div>
          </div>
        )}
      </ScrollPercentage>
    );
  }
}

export default BannerSmall