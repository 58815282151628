import React from 'react'
import './index.scss'

const VideoAndText = ({ video, YTvideo, headline, text, row, subtext, nobackground}) => (
  <div className={`video-and-text ${nobackground ? 'nobackground' : '' }`}>
    <div className="video-and-text__headline">
      <h3>{headline}</h3>
    </div>
    <div className={`video-and-text__container ${row ? 'row' : ''}`}>
      <div className="video-and-text__text">
        {text}
      </div>
      <div className="video-and-text__video">
        {video ? (
          <video controls>
            <source src={video} type="video/mp4" />
          </video>
        ) : (
          ''
        )}
        {YTvideo ? (
          <iframe src={`https://www.youtube-nocookie.com/embed/${YTvideo}?rel=0&enablejsapi=1`} />
        ) : (
          ''
        )}
      </div>
      <div className={`video-and-text__subtext`}>{subtext ? (subtext) : ('') }</div>
    </div>
  </div>
)

export default VideoAndText
