import React from 'react'
import './index.scss'

const AppointmentEvent = () => {
  if (typeof gtag === 'function') {
    const url = window.location.href;
    const baseUrl = 'http://localhost:8000';
    
    if (url.startsWith(baseUrl)) {
      const pathsToEvents = {
        '/technologie': 'Appointment-Technologie',
        '/individual-software': 'Appointment-Individual-Software',
        '/cms-vergleich': 'Appointment-cms-vergleich',
        '/customer-portal': 'Appointment-customer-portal',
        '/dsgvo-einfach': 'Appointment-dsgvo-einfach',
        '/girlsday-2022': 'Appointment-girlsday-2022',
        '/internet-explorer-death': 'Appointment-internet-explorer-death',
        '/matomo_statt_google_analytics': 'Appointment-matomo_statt_google_analytics',
        '/micro-interaction': 'Appointment-micro-interaction',
        '/mittels-seo-zur-besten-platzierung': 'Appointment-mittels-seo-zur-besten-platzierung',
        '/split-screens': 'Appointment-split-screens'
      };
  
      let eventName;
      for (const path in pathsToEvents) {
        if (url.includes(path)) {
          eventName = pathsToEvents[path];
          break;
        }
      }
  
      if (eventName) {
        let date = Date.now();
        gtag('event', eventName, {
          time: date,
          event_callback: function() {
            window.location.href = 'tel:+4369917407454';
          }
        });
      }
    }
  }
}


const Appointment = ({ noImage, image }) => (
  <div className="appointment">
    <div className="appointment__container">
      <span>Jetzt Ihren persönlichen</span>
      <h2> Beratungstermin vereinbaren</h2>
      <a  className="button" onClick={AppointmentEvent}>
        +43 (0) 699 174 074 54
      </a>
      {noImage ? '' : <img src={image} />}
    </div>
  </div>
)

export default Appointment
