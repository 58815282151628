import React, { Component } from 'react'
import axios from 'axios'
import './index.scss'

class ContactForm extends Component {
  state = {
    nameValue: '',
    emailValue: '',
    textValue: '',
    checked: false,
    error: false,
    errorMessage: '',
    pending: false,
    success: false,
    successMessage: ''
  }

  constructor(props) {
    super(props)
  }

  handleSubmit = event => {
    event.preventDefault()
    if (!this.validateFields()) return
    this.setState({
      error: false,
      errorMessage: '',
      pending: true
    })
    axios({
      method: 'post',
      url: '/api/submit-message',
      data: {
        message: this.state.textValue,
        from: this.state.emailValue,
        name: this.state.nameValue
      }
    })
      .then(result => {
        this.setState({
          pending: false,
          checked: false,
          nameValue: '',
          emailValue: '',
          textValue: '',
          success: true,
          successMessage:
            'Vielen Dank für deine Nachricht! Wir kümmern uns umgehend um deine Anfrage.'
        })
      })
      .catch(err => {
        this.setState({
          pending: false,
          checked: false,
          success: false,
          successMessage: '',
          error: true,
          errorMessage: 'Es ist leider etwas schief gelaufen.'
        })
      })
  }

  handleNameChange = event => {
    this.setState({
      nameValue: event.target.value
    })
  }

  handleEmailChange = event => {
    this.setState({
      emailValue: event.target.value
    })
  }

  handleTextChange = event => {
    this.setState({
      textValue: event.target.value
    })
  }

  toggleCheckbox = () => {
    this.setState({
      checked: !this.state.checked
    })
  }

  checkIfEmail = email => {
    const regex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    const valid = regex.test(String(email).toLowerCase())
    if (!valid) {
      this.setState({
        error: true,
        errorMessage: 'Bitte geben Sie eine gültige E-Mail Adresse an.'
      })
    }
    return valid
  }

  validateName = name => {
    const valid = name.length > 1 && !/\d/.test(name)
    if (!valid) {
      this.setState({
        error: true,
        errorMessage: 'Bitte geben Sie Ihren (!) Namen ein.'
      })
    }
    return valid
  }

  validateText = text => {
    const valid = text.length > 5
    if (!valid) {
      this.setState({
        error: true,
        errorMessage: 'Bitte genügend Text eingeben.'
      })
    }
    return valid
  }

  validateCheckbox = status => {
    if (!status) {
      this.setState({
        error: true,
        errorMessage: 'Bitte akzeptieren Sie die Datenschutzbestimmungen'
      })
    }
    return status
  }

  validateFields = () => {
    return (
      this.validateName(this.state.nameValue) &&
      this.checkIfEmail(this.state.emailValue) &&
      this.validateText(this.state.textValue) &&
      this.validateCheckbox(this.state.checked)
    )
  }

  render() {
    return (
      <div className="contact-form">
        <div className="contact-form__container">
          {!this.state.pending ? (
            !this.state.success ? (
              <form onSubmit={this.handleSubmit}>
                <h3 className="contact-form__headline">Schreiben Sie uns:</h3>
                <label htmlFor="name" className="contact-form__namelabel">
                  Name *
                </label>
                <input
                  className="input contact-form__name"
                  type="text"
                  name="name"
                  onChange={this.handleNameChange}
                  value={this.state.nameValue}
                  placeholder="Name"
                  autoFocus
                />
                <label htmlFor="email" className="contact-form__emaillabel">
                  E-Mail *
                </label>
                <input
                  className="input contact-form__email"
                  type="text"
                  name="email"
                  onChange={this.handleEmailChange}
                  value={this.state.emailValue}
                  placeholder="Email"
                />
                <label htmlFor="text" className="contact-form__textlabel">
                  Ihre Anfrage:
                </label>
                <textarea
                  className="textarea contact-form__text"
                  placeholder="Text"
                  onChange={this.handleTextChange}
                  value={this.state.textValue}
                />
                <div className="checkbox__wrapper">
                  <input
                    type="checkbox"
                    name="cbx"
                    id="cbx"
                    className="inp-cbx"
                    style={{ display: 'none' }}
                    onClick={this.toggleCheckbox}
                  />
                  <label className="cbx" htmlFor="cbx">
                    <span>
                      <svg width="12px" height="10px" viewBox="0 0 12 10">
                        <polyline points="1.5 6 4.5 9 10.5 1" />
                      </svg>
                    </span>
                    <label htmlFor="cbx">
                      Ich akzeptiere die holzweg{' '}
                      <a
                        href="/datenschutzerklaerung"
                        target="_blank"
                        className="link-effect"
                      >
                        Datenschutzbestimmungen
                      </a>
                    </label>
                  </label>
                </div>
                <button className="button button--color-black contact-form__button">
                  Senden
                </button>
                {this.state.error && (
                  <div className="message">{this.state.errorMessage}</div>
                )}
              </form>
            ) : (
              <div className="success">{this.state.successMessage}</div>
            )
          ) : (
            <div className="spinner" />
          )}
        </div>
      </div>
    )
  }
}

export default ContactForm
