import { groupBy } from 'lodash'

const shiftOrDefault = array => array.shift() || {}

export const mergeClasses = (...classNames) =>
  classNames
    .reduce((prev, curr) => prev.concat(curr ? curr.split(' ') : []), [])
    .join(' ')

export function createProjectSlides({ bigImages, smallImages }) {
  const bigSlides = bigImages.map(image => ({
    images: [image]
  }))

  const smallSlides = []
  while (smallImages.length > 0) {
    const images = []
    for (let i = 0; i < 2; i++) {
      images.push(shiftOrDefault(smallImages))
    }
    smallSlides.push({
      images
    })
  }

  let slides1 = smallSlides
  let slides2 = bigSlides

  if (slides1.length < slides2.length) {
    ;[slides2, slides1] = [slides1, slides2]
  }

  const ratio = Math.floor(slides1.length / slides2.length)
  let extra = slides1.length % slides2.length

  const slides = []
  while (slides1.length > 0 && slides2.length > 0) {
    for (let i = 0; i < ratio + (extra > 0 ? 1 : 0); i++) {
      slides.push(slides1.shift())
    }
    slides.push(slides2.shift())
    extra--
  }

  return slides
}

export const scrollTo = position =>
  window.scroll({
    top: position,
    left: 0,
    behavior: 'smooth'
  })

export const generateBreadcrumbsLinks = (path, title) => {
  if (path.slice(-1) === '/') {
    path = path.slice(0, -1)
  }
  const splitPath = path.split('/')
  const locations = splitPath.map((l, i) => ({
    path: splitPath.slice(0, i + 1).join('/') || '/',
    name: l.replace(/^./, str => str.toUpperCase())
  }))
  locations[locations.length - 1].name = title
  locations[0].name = 'Home'
  return locations
}

export const scrollTop = () => scrollTo(0)

export const scrollContainerMixin = Base =>
  class extends Base {
    scrollContainer = null

    setScrollContainerRef = ref => {
      this.scrollContainer = ref
    }

    scrollNext = () => {
      const { bottom } = this.scrollContainer.getBoundingClientRect()
      scrollTo(window.scrollY + bottom)
    }

    scrollPrev = () => {
      const { top } = this.scrollContainer.getBoundingClientRect()
      scrollTo(Math.max(window.scrollY + top - window.innerHeight, 0))
    }
  }

const noop = () => {}

export const exposeSliderMixin = Base =>
  class extends Base {
    swiper = null

    constructor(props) {
      super(props)
      this.state = {
        ...this.state,
        isEnd: false,
        isBeginning: false,
        notNeeded: false
      }
    }

    onInit = node => {
      if (node) {
        this.swiper = node.swiper
        this.checkBoundaries()
      }
    }

    handleDrag = event => {
      this.checkBoundaries()
    }

    checkBoundaries() {
      this.setState({
        isEnd: this.swiper.isEnd,
        isBeginning: this.swiper.isBeginning,
        notNeeded: this.swiper.isBeginning && this.swiper.isEnd
      })
    }

    getModsLeft() {
      if (this.state.notNeeded) return ['left', 'hide']
      return this.state.isBeginning ? ['left', 'disable'] : ['left']
    }

    getModsRight() {
      if (this.state.notNeeded) return ['right', 'hide']
      return this.state.isEnd ? ['right', 'disable'] : ['right']
    }

    nextSlide = () => {
      if (this.swiper) {
        this.swiper.slideNext()
        this.checkBoundaries()
      }
    }

    prevSlide = () => {
      if (this.swiper) {
        this.swiper.slidePrev()
        this.checkBoundaries()
      }
    }
  }

const months = [
  'Januar',
  'Februar',
  'März',
  'April',
  'Mai',
  'Juni',
  'Juli',
  'August',
  'September',
  'Oktober',
  'November',
  'Dezember'
]

export const getFormatedDate = date =>
  `${date.getDay()}. ${months[date.getMonth()]}`

export { SharedBackground } from './SharedBackground'
