import React from 'react'
import Link from 'gatsby-link'
import './index.scss'

const ProjectOverviewItem = ({
  customer,
  description,
  website,
  logo,
  backgroundImage,
  color,
  detailPageLink,
  altText
}) => (
  <div className="project-overview-item">
    {backgroundImage.endsWith('.mp4') ? (
      <div className="project-overview-item__background">
        <video autoPlay loop>
          <source src={backgroundImage} type="video/mp4" />
        </video>
      </div>
    ) : (
      <div
        className="project-overview-item__background"
        style={{ backgroundImage: `url(${backgroundImage})` }}
      />
    )}
    <div className="project-overview-item__overlay">
      <div className="project-overview-item__row">
        <div className="project-overview-item__logo">
          <img src={logo} alt={altText} />
        </div>
        <div className="project-overview-item__text">
          <table>
            <tbody>
              <tr>
                <th>Kunde:</th>
                <th>
                  <b>{customer}</b>
                </th>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div className="project-overview-item__row">
        <p className="project-overview-item__description">{description}</p>
        <div className="project-overview-item__buttons">
          {/* {website && (
            <a href={website} className="button" target="_blank">
              Website
              </a>
            )} */}
          {detailPageLink && (
            <Link
              to={detailPageLink}
              className="button button--detail"
              style={{ backgroundColor: `${color}`, borderColor: `${color}` }}
            >
              Details
            </Link>
          )}
        </div>
      </div>
    </div>
  </div>
)

export default ProjectOverviewItem
