import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faFacebookSquare,
  faInstagram,
  faLinkedin,
  faYoutube
} from '@fortawesome/free-brands-svg-icons'
import './index.scss'

import { mergeClasses } from '../../utils'

export default ({ className }) => (
  <div className={mergeClasses('holzweg-links', className)}>
    <a href="https://www.facebook.com/holzwegwebagentur" rel="nofollow"  target="_blank">
      <FontAwesomeIcon icon={faFacebookSquare} size="2x" />
    </a>
    <a
      href="https://www.linkedin.com/company/holzweg-e-commerce-solutions"
      target="_blank"
      rel="nofollow"
    >
      <FontAwesomeIcon icon={faLinkedin} size="2x" />
    </a>
    <a href="https://www.instagram.com/holzweg_gmbh" rel="nofollow"  target="_blank">
      <FontAwesomeIcon icon={faInstagram} size="2x" />
    </a>
    <a href="https://www.youtube.com/user/holzwegagentur" rel="nofollow"  target="_blank">
      <FontAwesomeIcon icon={faYoutube} size="2x" />
    </a>
  </div>
)
