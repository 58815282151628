import React, { useRef } from "react";
import './index.scss';
import ArrowButton from '../ArrowButton';

const BannerHero = ({ title, subtitle, image, video }) => {
    const containerRef = useRef(null);

    const scroll = () => {
        const { bottom } = containerRef.current.getBoundingClientRect();
        window.scrollTo({
            top: window.scrollY + bottom,
            behavior: 'smooth'
        });
    };

    return (
        <div ref={containerRef} className="bannerhero">
            {image && (
                <div className="bannerhero__image" style={{ backgroundImage: `url(${image})` }} />
            )}
            <div className="bannerhero__content">
                <h1 className="bannerhero__title">
                    {title}
                </h1>
                <p className="bannerhero__subtitle">
                    {subtitle}
                </p>
            </div>
            <span className="bannerhero__bottom">
                <ArrowButton mods={['color-white', 'down']} onClick={scroll}/>
            </span>
            {video && (
                <video autoPlay muted loop className="bannerhero__video">
                    <source src={video} type="video/mp4" />
                    Your browser does not support the video tag.
                </video>
            )}
        </div>
    );
};

export default BannerHero;
