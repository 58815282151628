import React from 'react'
import { memoize } from 'lodash'

export class SharedBackground extends React.Component {
  sharedBackground = null
  children = []

  state = {
    partialsBackgroundPositions: [],
    isHovered: []
  }

  setSharedBackgroundRef = element => {
    this.sharedBackground = element
  }

  getSetChildrenRef = memoize(i => element => {
    this.children[i] = element
  })

  setPartialsBackgroundPositions = () => {
    const sharedBackgroundClientRect = this.sharedBackground.getBoundingClientRect()
    const partialsBackgroundPositions = this.children
      .map(partial => {
        const clientRect = partial.getBoundingClientRect()
        return `${sharedBackgroundClientRect.x - clientRect.x}px ${sharedBackgroundClientRect.y - clientRect.y}px`
      })

    this.setState({ partialsBackgroundPositions })
  }

  toggleHover = i => () => this.setState(({ isHovered }) => {
    const newIsHovered = [...isHovered]
    newIsHovered[i] = !isHovered[i]
    return {
      isHovered: newIsHovered
    }
  })

  componentDidMount () {
    this.setPartialsBackgroundPositions()
    window.addEventListener('resize', this.setPartialsBackgroundPositions)
  }

  componentWillUnmount () {
    window.removeEventListener('resize', this.setPartialsBackgroundPositions)
  }

  render () {
    const { backgroundImage, hoverBackgroundImage, children, ...props } = this.props
    const { partialsBackgroundPositions, isHovered } = this.state
    const backgroundSize = this.sharedBackground ? `${this.sharedBackground.clientWidth}px` : null

    return (
      <div { ...props } ref={this.setSharedBackgroundRef}>
        {
          React.Children.map(children, (child, i) => React.cloneElement(child, {
            ref: this.getSetChildrenRef(i),
            style: {
              ...child.props.style,
              backgroundImage: isHovered[i] ? hoverBackgroundImage || backgroundImage : backgroundImage,
              backgroundPosition: partialsBackgroundPositions[i],
              backgroundSize
            },
            onMouseEnter: this.toggleHover(i),
            onMouseLeave: this.toggleHover(i)
          }))
        }
      </div>
    )
  }
}
