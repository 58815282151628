import React from 'react'
import './index.scss'
import BannerBox from './BannerBox'

const BannerLinks = ({ boxes = [] }) => {
  let singleImage = false
  let backgroundImage = ''

  boxes.forEach(box => {
    if (box.image === '' || box.image === null) {
      singleImage = true
    } else {
      backgroundImage = box.image
    }
  })
  return (
    <div
      className="banner-links"
      style={{
        backgroundImage: `${singleImage ? `url(${backgroundImage})` : ''}`
      }}
    >
      {boxes.map((box, i) => (
        <BannerBox {...box} key={i} singleImage={singleImage} />
      ))}
    </div>
  )
}

export default BannerLinks
