import React from 'react'
import ShowElement from '../ShowElement'
import { Motion, spring } from 'react-motion'
import HolzwegLogoSmall from '../HolzwegLogoSmall'
import './index.scss'

const QuickFacts = ({ facts, color }) => {
  return (
    <div className="quick-facts">
      <ShowElement threshold="0.05">
        {showElement => (
          <div className="quick-facts__wrapper">
            {facts.map((fact, index) => (
              <div className={`quick-fact ${color}`} key={index}>
                <div className="quick-fact__number">
                  <Motion
                    // on SSR render, start off with the actual value, so if JS doesn't run
                    // for whatever reason the values don't just stay at 0
                    defaultStyle={{ x: typeof window === 'undefined' ? fact.count : 0 }}
                    style={
                      showElement
                        ? {
                            x: spring(fact.count, {
                              stiffness: 50,
                              damping: 30
                            })
                          }
                        : {}
                    }
                  >
                    {value => (
                      <span>{Math.round(value.x).toLocaleString('de-DE')}</span>
                    )}
                  </Motion>
                  {fact.suffix}
                </div>
                <div className="quick-fact__text">{fact.text}</div>
              </div>
            ))}
          </div>
        )}
      </ShowElement>
      <div className={`quick-facts__background ${color}`}>
        <HolzwegLogoSmall className="quick-facts__holzweg-logo-small" />
      </div>
    </div>
  )
}

export default QuickFacts
