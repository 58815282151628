import React from 'react'
import { mergeClasses } from '../../utils'
const HolzwegLogoSmall = ({ className }) => {
  return (
    <svg className={ mergeClasses('holzweg-logo-small', className) } xmlns="http://www.w3.org/2000/svg" viewBox="0 0 30 50">
      <g>
        <rect stroke="null" height="10" width="10" fill="#EC6316" y="0" x="0"/>
        <rect stroke="null" height="10" width="10" fill="#EC6316" y="0" x="20"/>
        <rect stroke="null" height="10" width="10" fill="#F08608" y="10" x="10"/>
        <rect stroke="null" height="10" width="10" fill="#F4A302" y="20" x="20"/>
        <rect stroke="null" height="10" width="10" fill="#F5B616" y="30" x="10"/>
        <rect stroke="null" height="10" width="10" fill="#F5B616" y="40" x="0"/>
      </g>
    </svg>
  )
}

export default HolzwegLogoSmall
