import React from 'react'
import Link from 'gatsby-link'
import './index.scss'
import ArrowButton from '../ArrowButton'
import { scrollTop } from '../../utils'

const componentName = () => {
  return (
    <footer className="footer">
      <div className="footer__left">
        <div className="footer__company">&copy; holzweg GmbH</div>
        <div className="footer__address">
          <a
            className="link-effect"
            href="https://goo.gl/maps/gtrWWP3vW1F2"
            target="_blank"
          >
            Adamgasse 11 / 3. Stock / 6020 Innsbruck / Austria
          </a>
        </div>
      </div>
      <div className="footer__center">
        <ArrowButton mods={['up']} onClick={scrollTop} />
      </div>
      <div className="footer__right">
        <div className="footer__contact">
          <div className="footer__imprint link-effect">
            <Link to="/impressum">Impressum</Link>
          </div>
          <div className="footer__datenschutz link-effect">
            <Link to="/datenschutzerklaerung">Datenschutzerklärung</Link>
          </div>
          <a className="footer__email" href="mailto:office@holzweg.com">
            office@holzweg.com
          </a>
          <a className="footer__phone" href="tel:+43069917407403">
            +43 (0) 699 174 074 03
          </a>
        </div>
      </div>
      <img referrerPolicy="no-referrer-when-downgrade" src="https://matomo.holzweg.tv/matomo.php?idsite=1&amp;rec=1" style={{position: 'absolute', pointerEvents: 'none', opacity: 0}} alt="" />
    </footer>
  )
}

export default componentName
