import React, { Component } from 'react'
import './index.scss'

import Motor from '../../assets/software.stack.performance.02.jpg'

import apache from '../../assets/softwarestack/apache.png'
import css3 from '../../assets/softwarestack/css3.png'
import docker from '../../assets/softwarestack/docker.png'
import ezp from '../../assets/softwarestack/ezp.png'
import git from '../../assets/softwarestack/git.png'
import html5 from '../../assets/softwarestack/html5.png'
import jquery from '../../assets/softwarestack/jquery.png'
import js from '../../assets/softwarestack/js.png'
import magento from '../../assets/softwarestack/magento.png'
import nginx from '../../assets/softwarestack/nginx.png'
import nodejs from '../../assets/softwarestack/nodejs.png'
import php from '../../assets/softwarestack/php.png'
import react from '../../assets/softwarestack/react.png'
import symfony from '../../assets/softwarestack/symfony.png'
import typo3 from '../../assets/softwarestack/typo3.png'
import vue from '../../assets/softwarestack/vue.png'
import wordpress from '../../assets/softwarestack/wordpress.png'
import matomo from '../../assets/softwarestack/matomo.jpeg'
import googletagmanager from '../../assets/softwarestack/googletagmanager.png'
import typescript from '../../assets/softwarestack/typescript.png'
import varnish from '../../assets/softwarestack/varnish.png'
import svelte from '../../assets/softwarestack/svelte.png'

const cards = [
  {
    image: ezp,
    description1:
      'eZ Platform ist eines der führenden PHP-CMS basierend auf dem Symfony Full-Stack Framework.',
    description2:
      'Mit der Open-Source-Version ist eine unvergleichbare Flexibilität zur Erstellung, Erweiterung und Anpassung von Websites, Apps und anderen digitalen Produkten möglich. ',
    alt: 'eZ Publish'
  },
  {
    image: apache,
    description1:
      'Der Apache HTTP Server ist einer der meistbenutzten Webserver im Internet. Der Apache HTTP Server ist, wie alle Programme der Apache Software Foundation, eine freie Software. ',
    description2:
      'Der modulare Aufbau ermöglicht beispielsweise die Verschlüsselung der Kommunikation zwischen Browser und Webserver, den Einsatz als Proxyserver oder komplexe Manipulationen von HTTP-Headern und URLs.',
    alt: 'apache'
  },
  {
    image: nginx,
    description1:
      'NGINX ist ein weiterer HTTP Server und ist wie Apache modular aufgebaut. Das heißt, dass die verschiedenen Funktionen über entsprechende Module bereitgestellt werden.',
    description2:
      'Während Apache für jede Client-Anfrage einen neuen Thread oder Prozess öffnet, arbeitet der NGINX-Server ereignisorientiert. Somit können Anfragen asynchron bearbeiten werden, das zur Einsparung von Arbeitsspeicher und Zeit führt.',
    alt: 'nginx'
  },
  {
    image: magento,
    description1:
      'Die Onlineshop-Software Magento bietet als moderne Cloud-Commerce-Platform, flexible Lösungen für B2B und B2C. ',
    description2:
      'Durch diese Flexibilität und die Funktionen des Systems lässt sich Ihr Online-Shop einfach an die kundenspezifischen Bedürfnisse anpassen. Die Kaufentscheidung Ihrer Kund:innen kann durch intelligente Funktionen beeinflusst werden.',
    alt: 'magento'
  },
  {
    image: jquery,
    description1:
      'Die freie JavaScript-Bibliothek jQuery stellt Funktionen zur DOM-Navigation und -Manipulation zur Verfügung und ist eines der meistverwendeten JS-Bibliotheken.',
    description2: '',
    alt: 'jquery'
  },
  // {
  //   image: html5,
  //   description1:
  //     'HTML ist der definierte Standard für die Strukturierung, Vernetzung und plattformunabhängige Bereitstellung von Inhalten im World Wide Web.',
  //   description2:
  //     'Die aktuellste  Entwicklung HTML5 dient als Sammlung für Technologien, die vielfältige und leistungsstarke Webseiten und Anwendungen ermöglicht.',
  //   alt: 'html5'
  // },
  {
    image: css3,
    description1:
      'Cascading Style Sheets (CSS) ist eine Beschreibungssprache, die das Erscheinungsbild in HTML/XML-Dokumenten festlegt.',
    description2:
      'Sie gehört zu den Kernsprachen des Open Web und wird als Standard des W3C beständig weiterentwickelt.',
    alt: 'css3'
  },
  {
    image: typo3,
    description1:
      'Das lizenzfreie Open-Source-Enterprise-Content-Management-System (CMS) Typo3 stellt die Basis von über mehr als 500.000 Webseiten, Intranets und Web & Mobile Applikationen weltweit.',
    description2:
      'Das rein webbasierte CMS ist beliebig skalierbar und bietet einfache Lösungen für Mehrsprachigkeit und Multi-Domain Seiten an. Somit kann Typo3 auch für Ihren Unternehmensauftritt die perfekte Lösung sein.',
    alt: 'typo3'
  },
  {
    image: docker,
    description1:
      'Docker dient zur Isolierung von Anwendungen durch das Konzept der Container-Technologie.',
    description2:
      ' Ein Docker-Container enthält eine Anwendung, aber auch alle Ressourcen, die zur Laufzeit benötigt werden. Somit lassen sich Container mit einer leichtgewichtigen Virtualisierung vergleichen.',
    alt: 'docker'
  },
  {
    image: typescript,
    description1:
      'TypeScript wurde von Microsoft für großangelegte JavaScript Applikationen entwickelt und ermöglicht klassenbasierte typisierte Programmierung.',
    description2:
      'Durch diese starke Typisierung können bestimmte Fehler bereits zur Übersetzungszeit aufgedeckt werden.',
    alt: 'typescript'
  },
  {
    image: react,
    description1:
      'Die JavaScript-Bibliothek React JS stellt die Grundstruktur für das Erstellen von Benutzeroberflächen auf Webseiten zur Verfügung. ',
    description2:
      'Durch den Einsatz von einer modularen Komponentenarchitektur und des virtuellen DOM ergibt sich eine schnelle Renderingperformanz.',
    alt: 'react'
  },
  {
    image: php,
    description1:
      'Die Skriptsprache PHP wird hauptsächlich zur Erstellung von dynamischen Webseiten oder Webanwendungen verwendet. Sie zeichnet sich vor allem durch die breite Datenbankunterstützung und Internet-Protokolleinbindung sowie der Verfügbarkeit von zahlreichen Funktionsbibliotheken aus. ',
    description2:
      'Ende 2017 wurde auf 83% aller Webseiten PHP als serveitige Programmiersprache verwendet.',
    alt: 'php'
  },
  {
    image: js,
    description1:
      'Die Skriptsprache JavaScript ist eine der drei Standard-Technologien im Web neben HTML und CSS.',
    description2:
      'Mit JavaScript ist es möglich Benutzerinteraktionen auszuwerten, Inhalte zu verändern, nachzuladen oder zu generieren und so die Möglichkeiten von HTML und CSS zu erweitern.',
    alt: 'javascript'
  },
  {
    image: matomo,
    description1:
      'Das Webanalysetool matomo dient zur Erfassung von Datenverkehr auf Webseiten. Durch die gesammelten Daten wie z.B. Besucherzahl, -dauer und verhalten kann eine aussagekräftige Analyse über eine Webseite getroffen werden.',
    description2:
      'Matomo ist eine der führenden digitalen Analyse-Platformen, die 100 prozentigen Dateneigentum garantieren. Das war für uns der ausschlaggebende Punkt auf matomo zu wechseln.',
    alt: 'matomo'
  },
  {
    image: wordpress,
    description1:
      'Die Open-Source Software WordPress ist aufgrund ihrer Einfachheit und Benutzerfreundlichkeit eines der beliebtesten CMS.',
    description2:
      'Bereits 31 % der Internetseiten verwenden WordPress, vom Hobbyblog bis zu den größten Nachrichten-Webseiten im Netz. Mit über 45.000 Plugins kann die Webseite an Ihre Wünsche erweitert und angepasst werden.',
    alt: 'wordpress'
  },
  {
    image: symfony,
    description1:
      'Symfony ist ein PHP Framework und beinhaltet wiederwendbare PHP-Komponenten.',
    description2:
      'Ein Framework bündelt verschiedene Technologien, Konventionen und Best Practice Lösungen, um das entwickeln moderner Webseiten so einfach und strukturiert wie möglich zu gestalten.',
    alt: 'symfony'
  },
  {
    image: googletagmanager,
    description1:
      'Der  Google Tag Manager ist ein Tag-Management-System, das zur Verwaltung von JavaScript- und HTML-Tags für die Verfolgung und Analyse von Websites entwickelt wurde.',
    description2:
      'Dadurch können Code Snippets wie Tracking Codes oder Conversionpixel auf Webseiten und mobilen Apps eingebaut werden, ohne dabei in den Quellcode eingreifen zu müssen.',
    alt: 'google tag manager'
  },
  {
    image: nodejs,
    description1:
      'Node.js wird als Software-Plattform mit eventbasierter Architektur definiert, die es ermöglicht, die clientseitige Skriptsprache JavaScript serverseitig zu verwenden.',
    description2:
      'Mit dieser Laufzeitumgebung lassen sich leichtgewichtige Webserver realisieren und findet vor allem in der Entwicklung serverseitiger JavaScript-Anwendungen, die Datenmengen in Echtzeit bewältigen müssen, ihre Verwendung.',
    alt: 'node js'
  },
  {
    image: git,
    description1:
      'GitLab ist eine Webanwendung zur Versionsverwaltung für Softwareprojekte auf Basis von git.',
    description2:
      'Es wird von mehr als 100.000 Organisationen verwendet und hat mit 2.000 Leute, die zur Entwicklung beigetragen haben, eine große Community.',
    alt: 'git'
  },
  {
    image: varnish,
    description1:
      'Mit zunehmender Komplexität und steigender Nutzerzahlen verlieren dynamische Webprojekte deutlich an Performance.',
    description2:
      'Mit einem Reverse Proxy wie Varnish, der die Anfragen an den Webserver stellvertretend beantwortet, kann der Webserver entlastet werden. Dazu werden statische Inhalte wie Bilder im Cache gespeichert.',
    alt: 'varnish'
  },
  {
    image: vue,
    description1:
      'Vue.js ist ein clientseitiges JavaScript-Webframework zum Erstellen von Single-Page-Webanwendungen nach dem MVVM-Muster. Es kann allerdings auch in Multipage Webseiten für einzelne Abschnitte verwendet werden.',
    description2:
      'Das Framework hat den Grundgedanken, mit wenigen Zeilen Code gute Ergebnisse zu erzielen und durch den Einsatz des virtuellen DOM und einer Größe von 20 Kilobyte, ist es auch sehr performant.',
    alt: 'vue'
  },
  {
    image: svelte,
    description1:
      'Svelte ist ein clientseitiges JavaScript-Framework zur Entwicklung von Webanwendungen, das einen neuen Ansatz für die Erstellung von Benutzeroberflächen bietet. Es ermöglicht das Erstellen von effizienten Webanwendungen mit einem minimalen Footprint.',
    description2:
      'Das Framework zeichnet sich durch seine Fähigkeit aus, den Code während des Build-Prozesses in hoch optimierten JavaScript-Code umzuwandeln. Dadurch erreicht Svelte eine hervorragende Leistung und geringe Dateigrößen.',
    alt: 'svelte'
  }
  
]

class SoftwarePerformance extends Component {
  constructor(props) {
    super(props)
    this.state = {
      activeItem: null
    }
  }

  createSetActivateItem = activeItem => () => this.setState({ activeItem })

  toggleActiveItem = activeItem => () => {
    this.setState({
      activeItem: this.state.activeItem === activeItem ? null : activeItem
    })
  }

  render() {
    return (
      <div
        className={`software-preformance ${
          this.state.activeItem !== null ? 'software-preformance--active' : ''
        }`}
      >
        <div className="software-preformance__container">
          <div
            className="software-preformance__header"
            style={{ backgroundImage: `url(${this.props.image})` }}
          >
            <div className="software-preformance__headline headline">
              <h2>{this.props.headline}</h2>
            </div>
            <div className="software-preformance__description">
              <p>{this.props.text}</p>
            </div>
          </div>
          <div className="software-preformance__headline headline">
              <h2>Ein Einblick in unsere Technologien</h2>
            </div>
          <div className="software-preformance__content">
            {cards.map((card, index) => (
              <div
                className={`software-preformance__item ${
                  index === this.state.activeItem
                    ? 'software-preformance__item--active'
                    : ''
                }`}
                key={index}
                onMouseDown={this.toggleActiveItem(index)}
              >
                <img src={card.image} alt={card.alt} />
                <div className="software-preformance__text">
                  <p>{card.description1}</p>
                  <p>{card.description2}</p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    )
  }
}

export default SoftwarePerformance
