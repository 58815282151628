import React from "react";
import './index.scss'

import Bedarfsanalyse from '../../assets/icons/bedarfsanalyse.svg';
import UxUiDesign from '../../assets/icons/uxdesign_icon_1.svg';
import Verbesserung from '../../assets/icons/verbesserung.svg';
import TechnischeKonzeption from '../../assets/icons/technischeKonzeption.svg';
import Support from '../../assets/icons/support.svg';
import Implementierung from '../../assets/icons/implementierung.svg';


const GridList = () => {
    // https://www.svgrepo.com/svg/530440/machine-vision
    return (
        <div className="gridlist">
            <h2>Die holzweg Lösungen</h2>
            <p className="gridlist__text">
            Als spezialisierte Web-Agentur liegt unser Fokus auf der Entwicklung innovativer Typo3-Webanwendungen und komplexer Websites, bei denen Qualität im Vordergrund steht. Wir legen großen Wert auf einen engen Austausch mit unseren Kunden, um maßgeschneiderte Lösungen zu bieten, die sowohl technisch als auch strategisch überzeugen. Unser Ziel ist es, die Herausforderungen und Ziele Ihres Unternehmens genau zu verstehen und darauf aufbauend effektive Lösungen zu entwickeln.
            </p>
            <div className="gridlist__container">
                <div className="gridlist__item">
                    <div className="gridlist__item-head">
                        <img src={Bedarfsanalyse}/>
                        <h4>
                            Bedarfsanalyse und Beratung
                        </h4>
                    </div>
                    <div className="gridlist__item-content">
                        <p>Websiteziele werden definiert, Interne Abläufe werden mit Websitefunktionen abgeglichen und Synergiepotential wird erfasst. Lösungsoptionen werden gebrainstormed und anschließend gemeinsam besprochen.</p>
                    </div>
                </div>
                <div className="gridlist__item">
                    <div className="gridlist__item-head">
                        <img src={UxUiDesign}/>
                        <h4>
                            UX-/ UI-Design
                        </h4>
                    </div>
                    <div className="gridlist__item-content">
                        <p>Unser Ziel ist es, eine intuitive Benutzeroberfläche zu schaffen, die Nutzern ermöglicht, schnell zu finden, wonach sie suchen. Das Designteam entwirft ein maßgeschneidertes Websitedesign, das Ihre Unternehmensidentität widerspiegelt.</p>
                    </div>
                </div>
                <div className="gridlist__item">
                    <div className="gridlist__item-head">
                        <img src={TechnischeKonzeption}/>
                        <h4>
                            Technische Konzeption und Architektur
                        </h4>
                    </div>
                    <div className="gridlist__item-content">
                        <p>Wir wählen passende Technologien für Ihren Use-Case und berücksichtigen alle technischen Aspekte, auf die Sie sich verlassen können.</p>

                    </div>
                </div>
                <div className="gridlist__item">
                    <div className="gridlist__item-head">
                        <img src={Implementierung}/>
                        <h4>
                            Implementierung
                        </h4>
                    </div>
                    <div className="gridlist__item-content">
                        <p>In einem agilen SCRUM-Prozess erfolgt die Implementierung zeitgerecht und präzise, mit abschließenden Tests vor dem Livegang der Website.</p>
                    </div>
                </div>
                <div className="gridlist__item">
                    <div className="gridlist__item-head">
                        <img src={Support}/>
                        <h4>
                            Support
                        </h4>
                    </div>
                    <div className="gridlist__item-content">
                        <p>Unser Support-Service umfasst Updates, 2nd Level Support und SLA-Angebote. Wir agieren proaktiv und stehen Ihnen jederzeit zur Verfügung, unterstützt durch regelmäßige Abstimmungen, um bestmöglich auf Ihre Bedürfnisse einzugehen.</p>
                    </div>
                </div>
                <div className="gridlist__item">
                    <div className="gridlist__item-head">
                        <img src={Verbesserung}/>
                        <h4>
                            Kontinuierliche Verbesserung
                        </h4>
                    </div>
                    <div className="gridlist__item-content">
                        <p>Wir bieten kontinuierliche Überwachung und Analyse der Website-Performance sowie regelmäßige Updates und Optimierungen, um sicherzustellen, dass Ihre Website stets den aktuellen Anforderungen und Best Practices entspricht.</p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default GridList