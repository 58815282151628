import React, { useState, useEffect } from 'react';
import { Link } from 'gatsby';
import './Menu.scss';
import HolzwegLinks from '../HolzwegLinks';

const routes = [
  { text: "Individual Software", route: "/individual-software" },
  { text: "Kompetenzen", route: "/kompetenzen" },
  { text: "Strategie", route: "/strategie" },
  { text: "Technologie", route: "/technologie" },
  { text: "Projekte", route: "/projekte" },
  { text: "Internetagentur", route: "/internetagentur" },
  { text: "Webdesign", route: "/webdesign" },
  { text: "Blog", route: "/blog" },
];

const Menu = (props) => {
  const [currentRoute, setCurrentRoute] = useState('');

  useEffect(() => {
    const currentUrl = window.location.href;
    const url = new URL(currentUrl);
    const pathname = url.pathname;
    setCurrentRoute(pathname);
  }, []);

  return (
    <div className="menu">
      <div
        className={`
        menu__container
        ${props.showMenu ? 'menu__container--open' : ''}
      `}
      >
        <nav>
          <div className="menu__top">
            <ul className="navigation">
              {routes.map((route, index) => (
                <li key={index} className={currentRoute === route.route ? 'current' : ''}>
                  <Link
                    to={route.route}
                    onClick={props.toggleMenu}
                  >
                    {route.text}
                  </Link>
                </li>
              ))}
            </ul>
          </div>
          <div className="menu__bottom">
            <ul className="navigation-secondary">
              <li>
                <Link to="/kontakt" onClick={props.toggleMenu}>
                  Kontakt
                </Link>
              </li>
              <li>
                <Link to="/karriere" onClick={props.toggleMenu}>
                  Karriere
                </Link>
              </li>
            </ul>
            <HolzwegLinks className="menu__holzweg-links" />
          </div>
        </nav>
      </div>
    </div>
  );
};

export default Menu;
