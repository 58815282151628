import React from 'react'
import Link from 'gatsby-link'
import './index.scss'

import konzeption from '../../assets/kompetenzen/test.jpg'
import potential from '../../assets/kompetenzen/test.jpg'
import foerderung from '../../assets/kompetenzen/test.jpg'
import usecase from '../../assets/kompetenzen/test.jpg'
import onlinemarketing from '../../assets/kompetenzen/test.jpg'
import socialmedia from '../../assets/kompetenzen/test.jpg'
import seo from '../../assets/kompetenzen/test.jpg'
import trends from '../../assets/kompetenzen/test.jpg'
import ux from '../../assets/kompetenzen/test.jpg'
import wireframes from '../../assets/kompetenzen/test.jpg'
import animation from '../../assets/kompetenzen/test.jpg'
import programmierung from '../../assets/kompetenzen/test.jpg'
import multiportal from '../../assets/kompetenzen/test.jpg'
import pimdam from '../../assets/kompetenzen/test.jpg'
import progressiv from '../../assets/kompetenzen/test.jpg'
import shop from '../../assets/kompetenzen/test.jpg'
import crm from '../../assets/kompetenzen/test.jpg'
import individual from '../../assets/kompetenzen/test.jpg'
import schnittstellen from '../../assets/kompetenzen/test.jpg'
import datenbanken from '../../assets/kompetenzen/test.jpg'
import cms from '../../assets/kompetenzen/test.jpg'

const Competences = () => {
  return (
    <div className="competences">
      <section className="competences__wrapper">
      <div className="competences__headline headline">
          <h2 id="technologie">
            Tech
            <wbr />
            no
            <wbr />
            lo
            <wbr />
            gie
          </h2>
          <p>
            Entwicklungen aus unserer Hand können technisch hoch innovativ und
            komplex, aber auch standardisiert und schnell in der Umsetzung sein.
            Wir richten uns nach Ihrem Bedarf und liefern genau die Lösung, die
            Ihr Unternehmen benötigt!
          </p>
        </div>
        <div className="competences__container">
          <div
            className="competences__item"
            style={{ backgroundImage: `url('${programmierung}')` }}
          >
            <h3 id="website-programmierung">Website- Programmierung</h3>
            <p>
              Ihre Firmenwebsite ist nicht mehr state-of-the-art und spricht
              Ihre Kund:innen nicht mehr an? Sie hatten noch nie eine eigene
              Unternehmensseite und möchten nun auch endlich im Web
              professionell vertreten sein? Wir verstehen unser Handwerk und
              gestalten die für Sie perfekte Homepage mit der für Sie perfekten
              Lösung!{' '}
            </p>
          </div>
          <div
            className="competences__item"
            style={{ backgroundImage: `url('${multiportal}')` }}
          >
            <h3 id="multiportal-systeme">
              Multiportal
              <wbr />
              systeme
            </h3>
            <p>
              Unsere Multiportalsysteme erlauben eine einfache, schnelle und
              sichere Verwaltung komplexer Content- und Informationsstrukturen.
              Erstellen Sie mit wenig Aufwand und geringen Kosten neue
              Markenwebsites und kommen Sie mit neuen Produkten noch schneller
              auf den Markt.
            </p>
          </div>
          <div
            className="competences__item"
            style={{ backgroundImage: `url('${pimdam}')` }}
          >
            <h3 id="pim-dam-systeme">PIM-/DAM-Systeme</h3>
            <p>
              Produktinformationsmanagement - Deutliche Effizienzsteigerung von
              internen Prozessen durch das zentrale Einpflegen und Warten von
              Produktdaten. Wir helfen Ihnen dabei, dass all Ihre
              Vertriebskanäle mit konsistenter Information beliefert werden
              können - in verschiedenen Sprachen und Kommunikationssystemen.
            </p>
            <Link to="/individual-software"></Link>
          </div>
          <div
            className="competences__item"
            style={{ backgroundImage: `url('${progressiv}')` }}
          >
            <h3 id="progressive-web-app">Progressive Web-App</h3>
            <p>
              Sie suchen nach der perfekten App-Lösung für Ihre Idee? Wir
              schwören auf die Technologie von progressive Web-Apps! Neben einer
              sehr kostengünstigen Umsetzung bieten sie den Vorteil, dass sie
              unabhängig von Stores mit nur einem Klick installiert werden
              können. Trotzdem ist Ihre App auch offline verwendbar und bietet
              neben einer hohen Performance den Vorteil, dass sie über
              Suchmaschinen gefunden wird.
            </p>
          </div>
          <div
            className="competences__item"
            style={{ backgroundImage: `url('${shop}')` }}
          >
            <h3 id="shop-loesungen">Shop-Lösungen</h3>
            <p>
              Wir sind Ihr kompetenter Ansprechpartner, wenn es um das Thema
              Web-Shops geht. Ob B2B oder B2C, wir bieten die richtige
              Softwarelösung für Ihr Business!{' '}
            </p>
          </div>
          <div
            className="competences__item"
            style={{ backgroundImage: `url('${crm}')` }}
          >
            <h3 id="crm">CRM</h3>
            <p>
              Customer Relationship Management. Alle Informationen über Kund:innen,
              Partner etc. machen nur Sinn, wenn sie von Ihnen regelmäßig und
              sinnvoll gewartet werden. Ein System von holzweg zentralisiert die
              Daten und hilft Ihnen dabei, mehr zu wissen und besser zu
              kommunizieren. Was das für Sie konkret heißt: Umsatzsteigerung,
              Kundenbindung, Kostensenkung!
            </p>
          </div>
          <div
            className="competences__item"
            style={{ backgroundImage: `url('${individual}')` }}
          >
            <h3 id="individual-software">
              Individual
              <wbr />
              software
            </h3>
            <p>
              Es muss nicht alles neu erfunden werden, aber dennoch begeben wir
              uns gerne mit Ihnen gemeinsam auf neue Pfade und entwickeln
              individuelle Highend-Lösungen für Ihre anspruchsvollen und
              innovativen Geschäftsmodelle. Dort wo Standardsoftware nicht mehr
              passt, erfinden wir eben doch Neues - vom ersten Bit für unsere
              Kund:innen gemacht!
            </p>
            <Link to="/individual-software"></Link>
          </div>
          <div
            className="competences__item"
            style={{ backgroundImage: `url('${schnittstellen}')` }}
          >
            <h3 id="schnittstellen">
              Schnitt
              <wbr />
              stellen
            </h3>
            <p>
              Um Sie vor wiederkehrenden stupiden Arbeiten zu verschonen,
              konzipieren wir für Sie die perfekte Lösung. Schnittstellen
              verhindern, dass Ihre Datensätze auseinanderlaufen. Copy&paste
              gehört der Vergangenheit an! Ob Schnittstellenimport, Import- und
              Export Routinen für den automatisierten Datenaustausch oder die
              Vernetzung Ihrer Systeme - wir erleichtern Ihre Arbeit!
            </p>
            <Link to="/individual-software"></Link>
          </div>
          <div
            className="competences__item"
            style={{ backgroundImage: `url('${datenbanken}')` }}
          >
            <h3 id="datenbanken">
              Daten
              <wbr />
              banken
            </h3>
            <p>
              Scheuen Sie sich nicht, uns mit jeglichen Fragen rund um das
              komplexe Themengebiet der Datenbanken zu konfrontieren! Wir
              verstehen schwierige Zusammenhänge und erarbeiten mit großer
              Leidenschaft die beste Lösung für all Ihre Fragestellungen!
            </p>
          </div>
          <div
            className="competences__item"
            style={{ backgroundImage: `url('${cms}')` }}
          >
            <h3 id="cms">CMS</h3>
            <p>
              Content Management Systeme ermöglichen es Ihnen, dass Sie selbst
              die Inhalte Ihrer Webseite verwalten und organisieren können. Über
              Rechtevergaben können Sie entscheiden, wer von Ihren Autoren an
              welchen Orten Bilder, Texte etc. bearbeiten darf. Wir unterstützen
              Sie bei der Wahl des für sie am besten geigneten CMS (z.B.
              Wordpress, Typo3, eZ Platform) und setzen Ihr Website auf dieser
              Basis um.
            </p>
          </div>
        </div>
        <div className="competences__headline headline">
          <h2 id="strategie">Strategie</h2>
          <p>
            Wir entwickeln gemeinsam mit Ihnen Strategien für Ihre Webprobjekte.
            Auf Wunsch analysieren wir Ihre Internetpräsenz und Ihre digitalen
            Prozesse und erarbeiten Lösungen, die für Sie passen. Unsere
            Beratungen sind so individuell wie unsere Kund:innen.
          </p>
        </div>
        <div className="competences__container">
          <div
            className="competences__item"
            style={{ backgroundImage: `url('${konzeption}')` }}
          >
            <h3 id="konzeption">Konzeption</h3>
            <p>
              Ihre Ziele packen wir in einen strukturierten Rahmen! Von der
              Bedarfserhebung und Definition von konkreten Anforderungen
              (Lastenheft) über die Ausdefinition der konkreten Funktionen
              (Pflichtenheft) bis hin zur Konkretisierung wichtiger
              Projektparameter wie Zeitplan und Ressourcenplan, begleiten wir
              Ihr Vorhaben durch alle Projektphasen.
            </p>
          </div>
          <div
            className="competences__item"
            style={{ backgroundImage: `url('${potential}')` }}
          >
            <h3 id="potentialanalyse">
              Potential
              <wbr />
              analyse
            </h3>
            <p>
              Unsere zertifzierten Consultants durchleuchten mit Ihnen
              gemeinsams Ihren digitalen Status-quo und decken Potentiale auf.
              Nutzen Sie unser Know-how und holen Sie sich Ihren
              Beratungstermin.
            </p>
          </div>
          <div
            className="competences__item"
            style={{ backgroundImage: `url('${foerderung}')` }}
          >
            <h3 id="foerdermanagement">Fördermanagement</h3>
            <p>
              Welche Fördermöglichkeiten gibt es für Ihre Idee oder Ihr Projekt?
              Wir erarbeiten mit Ihnen Möglichkeiten, helfen beim Förderansuchen
              und übernehmen auch gerne das Fördermanagement für Sie.
            </p>
          </div>
          <div
            className="competences__item"
            style={{ backgroundImage: `url('${usecase}')` }}
          >
            <h3 id="usecases-personas">Use Cases & Personas</h3>
            <p>
              Wechseln Sie mit uns einfach mal die Perspektive. Überlegen wir
              uns, wie Ihre typischen User denken, was sie sich erwarten, was
              sie brauchen. Das Ausarbeiten von typischen Szenarien und das
              überblicksartige Abbilden von Zusammenhängen helfen bei der
              konkreten Zieldefinition. Diese Überlegungen bilden die Basis für
              die Entwicklung der Benutzeroberfläche Ihrer Lösung.
            </p>
          </div>
          <div
            className="competences__item"
            style={{ backgroundImage: `url('${onlinemarketing}')` }}
          >
            <h3 id="online-marketing">Online Marketing</h3>
            <p>
              Werden Sie von Ihren Kund:innen gefunden! Wir erarbeiten mit Ihnen
              eine klare Online Marketing Strategie und planen zielgerichtete
              Kampagnen auf Google, Facebook, Whatsapp etc. Wir betreuen Ihre
              Kampagnen und liefern regelmäßige und aussagekräftige Berichte,
              die zur Optimierung der Strategie beitragen.
            </p>
          </div>
          <div
            className="competences__item"
            style={{ backgroundImage: `url('${socialmedia}')` }}
          >
            <h3 id="socialmedia">Social Media</h3>
            <p>
              Wo liegen die Möglichkeiten und Potentiale von Facebook und Co.?
              Wir schauen uns mit Ihnen die Trends an, erarbeiten mit Ihnen eine
              sinnvolle Strategie und erstellen gemeinsam einen Redaktionsplan.
            </p>
          </div>
          <div
            className="competences__item"
            style={{ backgroundImage: `url('${seo}')` }}
          >
            <h3 id="seo">SEO</h3>
            <p>
              Wir helfen Ihnen zu einer Top Positionierung in den Suchmaschinen
              durch SEO Analysen, Konzepte, konkrete Vorschläge und regelmäßigem
              Monitoring. Gerne führen wir Vorort-Schulungen durch und machen
              Ihr Team SEO-fit.
            </p>
          </div>
          <div
            className="competences__item"
            style={{ backgroundImage: `url('${trends}')` }}
          >
            <h3 id="trends">Trends</h3>
            <p>
              Bleiben Sie mit uns am Ball der Zeit! Wir beobachten Trends und
              informieren unsere Kund:innen über Entwicklungen und Möglichkeiten.
              Wir möchten, dass Sie digital fit bleiben und unterstützen Sie
              dabei mit unserem Know-how!
            </p>
          </div>
        </div>
        <div className="competences__headline headline">
          <h2 id="design">Design</h2>
          <p>
            Die Webdesigns von holzweg zeichnen sich nicht nur durch ihre Schönheit und Modernität aus, sondern sind auch auf die Bedürfnisse der Benutzer:innen ausgerichtet. Wir beeindrucken Sie mit innovativen und bahnbrechenden Elementen. Zudem legen wir großen Wert darauf, dass unsere Designs Ihren Anforderungen und Ihrer Corporate Identity entsprechen.
          </p>
        </div>
        <div className="competences__container">
          <div
            className="competences__item"
            style={{ backgroundImage: `url('${ux}')` }}
          >
            <h3 id="user-experience-design">User Experience Design</h3>
            <p>
              Webdesigns von holzweg sind nicht nur schön, sondern auch
              benutzerorientiert. Unsere Web- und Software-Designs passen für
              alle Endgeräte, egal ob PCs, Tablets oder Smartphones.
            </p>
          </div>
          <div
            className="competences__item"
            style={{ backgroundImage: `url('${wireframes}')` }}
          >
            <h3 id="wireframes-mockups">Wireframes / Mockups</h3>
            <p>
              Gemeinsam mit Ihnen können wir die Strukturen und Elemente der
              Weblösung optimal konzipieren. Bereits in einer sehr frühen Phase
              des Projekts erhalten Sie dank Wireframes und Mockups eine
              konkrete Vorstellung über Aufbau und Funktion Ihrer Website oder
              die Benutzeroberfläche Ihrer Softwarelösung.
            </p>
          </div>
          <div
            className="competences__item"
            style={{ backgroundImage: `url('${animation}')` }}
          >
            <h3 id="animation">Animation</h3>
            <p>
              Mit Animationen können Sie Ihren Kund:innen noch mehr Service bieten!
              Z.B. als Kurzvideos in Form von Gebrauchsanweisungen oder
              Installationsanleitungen oder als animierte Landkarten zur
              besseren Suche des nächsten Shops oder Ansprechpartners. Es gibt
              so viele Möglichkeiten, überlegen Sie mit uns gemeinsam!
            </p>
          </div>
        </div>
      </section>
    </div>
  )
}

export default Competences
