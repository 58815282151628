import React from 'react';
import './index.scss';

const HoverButton = ({ buttonStyle, dataText, dataTitle, link, onClick }) => {
  const customStyle = {};
  if (buttonStyle) {
    customStyle['--primary-color'] = buttonStyle;
  }

  const handleButtonClick = () => {
    if (link) {
      window.open(link, '_blank');
    }
    onClick && onClick();
  };

  return (
    <div className="buttons">
      <button className="btn header-big__button" style={customStyle} onClick={handleButtonClick}>
        <span></span>
        <p data-text={dataText} data-title={dataTitle}></p>
      </button>
    </div>
  );
};

export default HoverButton;
