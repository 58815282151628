import React, { Component } from 'react'
import './index.scss'

class Image extends Component {
  state = {
    expanded: false,
  }

  constructor (props) {
    super(props)
  }

  handleClick = () => {
    this.setState(({ expanded }) => ({
      expanded: !expanded
    }))
  }

  render() {
    return (
      <div className={
        this.props.disablePaddingbottom 
            ? 'image padding-bottom-none' 
            : 'image'
      }>
        <div
          className={
            this.state.expanded
              ? 'image__container expanded'
              : 'image__container'
          }
          onClick={this.handleClick}
        >
          <img src={this.props.image} />
          <p>{this.props.title}</p>
        </div>
      </div>
    )
  }
}

export default Image
