import React from 'react'
import './index.scss'
import IphoneX from '../../assets/iphoneX.png'

const Phone = ({ title, description, mainDescription, link, bgColor }) => {
  return (
    <div className="phone">
      <div className="phone__container">
        <div className="phone__side">
          <h4>{title}</h4>
          <p>{description}</p>
          <h3>{mainDescription}</h3>
        </div>
        <div className="phone__side">
          <div className="phone__phone">
            <img src={IphoneX} />
            <iframe src={link} scrolling="yes"/>
            <p>
              Scrollen Sie im Smartphone!
            </p>
          </div>
        </div>
      </div>
      <div className="phone__background" style={{ backgroundColor: bgColor }} />
    </div>
  )
}

export default Phone
