import React from 'react'
import './index.scss'
import ProjectOverviewItem from './ProjectOverviewItem'

const tiwag = '/tiwag.01.mp4'
import tq from '../../assets/slider.tqimg.png'
import tiwagLogo from '../../assets/tiwag.logo.png'
import tqlogo from '../../assets/tq.logo.png'
import rauchmehl from '../../assets/image.png'
import rauchmehlLogo from '../../assets/rauchmehl.png'
import edelwatt from '../../assets/edelwatt.uebersicht.jpg'
import stadtamthall from '../../assets/stadtamthall.uebersicht.jpg'
import stadtamthallLogo from '../../assets/stadtamthall.logo.png'
import stubai from '../../assets/stubai.uebersicht.jpg'
import stubailogo from '../../assets/stubai.logo.png'
import halllogo from '../../assets/hall.logo.png'
import hall from '../../assets/hall.uebersicht.jpg'
import ikb from '../../assets/ikb.uebersicht.jpg'
import ikblogo from '../../assets/ikb.logo.png'
import aklogo from '../../assets/logo.arbeiterkammer.png'
import akbg from '../../assets/header.arbeiterkammer.png'
import wedl from '../../assets/header.wedl.jpg'
import wedllogo from '../../assets/logo.wedl.png'
import gskgebro from '../../assets/header.voltadol.jpg'
import haleon from '../../assets/brands/haleon_white.png'
import klepsch from '../../assets/header.klepschgroup.jpg'
import klepschlogo from '../../assets/logo.klepsch.png'
import intercable from '../../assets/header.intercable.jpg'
import intercablelogo from '../../assets/logo.intercable.png'
import berufsreise from '../../assets/header.berufsreise.jpg'
import berufsreiselogo from '../../assets/logo.berufsreise.png'
import eglo from '../../assets/headerbild.pim.jpg'
import eglologo from '../../assets/logo.eglo.png'
import morf from '../../assets/banner.morf.jpg'
import morflogo from '../../assets/logo.morf.png'
import dibklogo from '../../assets/dibk.uebersicht.logo.png'
import dibk from '../../assets/dibk.uebersicht.jpg'
import nht from '../../assets/header.nht.png'
import whw from '../../assets/header.whw.png'
import nhtlogo from '../../assets/logo.nht.png'
import whwlogo from '../../assets/logo.whw.png'
import etlogo from '../../assets/logo.energietirol.png'
import et from '../../assets/header.energietirol.uebersicht.png'
import bhlogo from '../../assets/logo.bioheizwerk.jpeg'
import bh from '../../assets/header.bioheizwerk.uebersicht.png'
import colomlogo from '../../assets/logo.colompac.png'
import colompac from '../../assets/header.colompac.uebersicht.png'
import aalogo from '../../assets/logo.augenaerztin.png'
import aa from '../../assets/header.augenaerztin.uebersicht.png'
import aluplogo from '../../assets/logo.alupress.png'
import alup from '../../assets/header.alupress.uebersicht.png'
import alupress from '../../assets/banner.alupress.png'
import panlogo from '../../assets/logo.pan.svg'
import pan from '../../assets/header.pan.uebersicht.png'
import museumhalllogo from '../../assets/logo.stadtmuseumhall.jpeg'
import museumhall from '../../assets/header.stadtmuseumhall.uebersicht.png'
import wkologo from '../../assets/logo.wko.png'
import wko from '../../assets/header.wko.uebersicht.png'
import alpinamed from '../../assets/alpinamed-referenz-banner.webp'
import alpinlogo from '../../assets/logo-alpinamed.png'

const projects = [
  {
    customer: 'Innsbrucker Kommunalbetriebe',
    project: 'Website Betreuung seit 2014',
    description:
      'Als kommunales Infrastruktur-Dienstleistungsunternehmen dient die Webseite der IKB vor allem als Informationsportal und somit als weiterer Service für ihre Kund:innen. Bei einer Webseite mit so vielen Inhalten wie auf ikb.at steht die Skalierbarkeit aus technischer Sicht an erster Stelle. ',
    website: 'https://www.ikb.at',
    logo: ikblogo,
    backgroundImage: ikb,
    color: '#ec2427',
    detailPageLink: '/projekte/ikb',
    altText: 'Website ikb.at'
  },
  {
    customer: 'Alpinamed',
    project: 'Website Relaunch',
    description:
      'Gemeinsam mit dem zuständigen Team der Alpinamed AG stellten wir ihre Website mit neuem Design, besserer Performance und optimierteren SEO-Einstellungen erneut live.',
    website: 'https://www.alpinamed.at',
    logo: alpinlogo,
    backgroundImage: alpinamed,
    color: '#B10F34',
    detailPageLink: '/projekte/alpinamed',
    altText: 'Website Alpinamed'
  },
  {
    customer: 'Morf AG',
    project: 'Custom ERP für passgenaue digitale Arbeitsabläufe',
    description:
      'Verschönern und für Orientierung sorgen - seit 50 Jahren ist das die Mission der Schweizer Morf AG. Und damit ihre Arbeit im Bereich Straßen-Markierung und Signalisation mit Leichtigkeit funktioniert, entwickeln wir in enger Zusammenarbeit eine Web-Applikation für die gesamte Auftragsabwicklung.',
    website: 'https://www.morf-ag.ch/',
    logo: morflogo,
    backgroundImage: morf,
    color: '#FFD400',
    detailPageLink: '/hw-erp',
    altText: 'Website Morf'
  },
  {
    customer: 'EGLO Leuchten',
    project: 'B2B Shopsystem und PIM-System',
    description:
      'Gemeinsam mit unserem langjährigen Kunden EGLO wurde ein B2B Shopsystem für über 38.000 Produkte ins Leben gerufen. Desweiteren wurden wir vom Weltmarktführer für die Erstellung eines internen Produktinformationsmanagement (PIM) System für über 240.000 Produkte beauftragt.',
    logo: eglologo,
    backgroundImage: eglo,
    color: '#23A1A9',
    detailPageLink: '/projekte/eglo-pim',
    altText: 'Website Akeneo'
  },
  {
    customer: 'Haleon-Gebro',
    project: 'Kundenorientierte Multidomain-Website',
    description:
      'Ob Lamisil, Otrivin, Voltadol, Fenistil, Vitawund oder Chlorhexamed - die Arzneien haben einiges gemeinsam: neben ihrem Erzeuger Haleon Gebro Consumer Health GmbH dürfen sie nun alle im neuen und individuellen Onlinedesign von holzweg auftreten.',
    website: 'https://www.voltadol.at/',
    logo: haleon,
    backgroundImage: gskgebro,
    color: '#2ee01c',
    detailPageLink: '/projekte/haleon-gebro',
    altText: 'Multidomain Website Haleon-Gebro'
  },
  {
    customer: 'Wirtschaftskammer Tirol',
    project: 'Digitale Lernwelt für Lehrlinge',
    description:
      'Mit der Lernplattform "Deine Lehre", auf Open Source Basis, können individuelle Anforderungen umgesetzt werden. Mit der Verwendung von Moodle als Lernplattform können Lernmaterialien als Kurse bereitgestellt werden und durch die Integration von H5P ist die Möglichkeit gegeben interaktive Lerninhalte zu erstellen. ',
    website: 'https://textil.deinelehre.at/login',
    logo: wkologo,
    backgroundImage: wko,
    color: '#DF1526',
    detailPageLink: '/projekte/wirtschaftskammer',
    altText: 'Website Lernplattform'
  },
  {
    customer: 'alupress',
    project: 'Website Relaunch',
    description:
      'Eine Mulitportallösung auf Basis von Typo3. Die klaren Strukturen, die übersichtliche Menüführung und die mehrsprachige Auflegung bieten den Usern ein angenehmes und freundliches Interface. ',
    website: 'https://www.alupress.com/',
    logo: aluplogo,
    backgroundImage: alupress,
    color: '#00a7e5',
    detailPageLink: '/projekte/alupress',
    altText: 'Website alupress'
  },
  {
    customer: 'Rauchmühle',
    project: 'Relaunch Website',
    description:
      'Die neue Website der Rauchmühle überzeugt mit vielen Bildern und übersichtlicher Produktpräsentation. Wordpress sorgt für eine einfache und schnelle Wartung. Monatliche Rezeptvorschläge erfreuen alle koch- und backaffine User.',
    website: 'http://www.rauchmehl.at',
    logo: rauchmehlLogo,
    backgroundImage: rauchmehl,
    color: '#009a3d',
    detailPageLink: '/projekte/rauchmehl',
    altText: 'Relaunch Website'
  },
  {
    customer: 'Klepsch-Gruppe',
    project: 'Redesign Multiportalsystem',
    description:
      'Das Multiportalsystem der Klepsch-Gruppe wurde einem Soft-Redesign unterzogen, um dem State-of-the-art des Webs zu folgen. Das Redesign umfasste ein Umgestalten aller Contentelemente, um diese lese-freundlicher zu machen.',
    website: 'https://www.klepschgroup.com/de/startseite',
    logo: klepschlogo,
    backgroundImage: klepsch,
    color: '#ca1e27',
    detailPageLink: '/projekte/klepsch-group',
    altText: 'Website Klepsch-group'
  },
  {
    customer: 'Neue Heimat Tirol',
    project: 'Intranet',
    description:
      'Für Organisationen, die ihre Geschäftsprozesse optimieren und die Kommunikation sowie den Zugriff auf Informationen innerhalb des Unternehmens erleichtern wollen, gibt es keine bessere Option als die Implementierung eines Intranets.',
    website: 'https://neueheimat.tirol/',
    logo: nhtlogo,
    backgroundImage: nht,
    detailPageLink: '/projekte/nht',
    color: '#981A34',
    altText: 'Zur Website'
  },
  {
    customer: 'Diözese Innsbruck',
    project: 'Soft-Relaunch Website',
    description:
      'Die Diözese Innsbruck ist einer unserer Lieblings-Stammkunden. Seit 2003 vertrauen sie auf die Konzeption und Umsetzung der holzweg GmbH. Dieses Mal wurde das Design dezent überarbeitet und ein paar neue Features eingebaut. Seht selbst: ',
    website: 'https://www.dibk.at',
    logo: dibklogo,
    backgroundImage: dibk,
    detailPageLink: '/projekte/dioezese',
    color: '#FEA000',
    altText: 'Zur Website'
  },
  {
    customer: 'Intercable',
    project: 'Multiportalsystem und responsive Webshop',
    description:
      'Für das Multiportalsystem intercable.it wurde die OpenSource Lösung eZ-Publish eingesetzt. Für ein strukturiertes Management der Inhalte, ist der Auftritt bis dato in drei Portale gegliedert.',
    website: 'https://www.intercable.com/de/',
    logo: intercablelogo,
    backgroundImage: intercable,
    color: '#ca1e27',
    detailPageLink: '/projekte/intercable',
    altText: 'Website Intercable'
  },
  {
    customer: 'Hall.AG',
    project: 'Soft-Relaunch Website',
    description:
      'Eine Website, die inhaltlich gut funktioniert und vom Design her gut ankommt, aber doch ein wenig moderner sein könnte, bekommt einen Soft-Relaunch. Kund:innen finden sich nach wie vor super zurecht, da vieles gleich bleibt. Trotzdem schaut alles ein bisschen neu aus!',
    website: 'https://www.hall.ag',
    logo: halllogo,
    backgroundImage: hall,
    color: '#e30613',
    altText: 'Relaunch Website'
  },
  {
    customer: 'Stadtamt Hall',
    project: 'Relaunch Website',
    description:
      'Vorhang auf für das Stadtamt Hall in Tirol! Die neu gestaltete Website überzeugt mit einem aufgeräumten, aber sehr lebendigen Design, einer übersichtlichen Navigation zu allen Bürgerthemen und vielen Details sowie zahlreichen Informationen. Wenn Sie Hall noch nicht lieben, könnte Ihnen diese Seite dabei helfen ❤',
    website: 'https://www.hall-in-tirol.at/',
    logo: stadtamthallLogo,
    backgroundImage: stadtamthall,
    color: '#ca1e27',
    detailPageLink: '/projekte/stadtamthall',
    altText: 'Relaunch Website'
  },
  {
    customer: 'Wedl Handelshaus',
    project: 'Relaunch der Wedl-Website',
    description:
      'Vor ein paar Jahren konnten wir eine neue, kundenorientierte Website für das Wedl Handelshaus mit emotionaler Bildsprache, fesselnden Inhalten und Fokus auf unkomplizierter Jobsuche an verschiedenen Standorten entwickeln. Die interaktive Karte zeigt die Ansprechpartner und Standorte für eine benutzerfreundliche Erfahrung.',
    website: 'https://www.wedl.com',
    logo: wedllogo,
    backgroundImage: wedl,
    color: '#ca1e27',
    detailPageLink: '/projekte/wedl',
    altText: 'Website Wedl'
  },
  {
    customer: 'Arbeiterkammer Tirol',
    project: 'Online mit Köpfchen',
    description:
      'In enger Zusammenarbeit mit dem Kunden wurde ein Tool für die Durchführung von Workshops inklusive Quiz konzipiert und als Web-App umgesetzt. Anhand von ausgewählten Beispielen sollen die Schülerinnen und Schüler das Handeln im Internet hinterfragen und hilfreiche Informationen für die eigene Anwendung bekommen.',
    website: 'https://omk.tirol/student/login',
    logo: aklogo,
    backgroundImage: akbg,
    color: '#00a7e5',
    detailPageLink: '/projekte/arbeiterkammer',
    altText: 'Website Arbeiterkammer'
  },
  {
    customer: 'TQ-Systems',
    project: 'Relaunch Multiportalsystem',
    description:
      'Wie bringt man Mikrocontroller, E-Bike-Antriebe und flug- und medizintechnische Geräte mit neuer Corporate Identity unter einen Hut? Sehen Sie selbst:',
    website: 'https://www.tq-group.com',
    logo: tqlogo,
    backgroundImage: tq,
    color: '#00a7e5',
    detailPageLink: '/projekte/relaunch-tq-group',
    altText: 'Relaunch Multiportale'
  },
  {
    customer: 'Pan',
    project: 'Multilinguale Website',
    description:
      'Eine übersichtliche Website in 3 Sprachen (DE, IT, ENG). Klare Menüstruktur ausgeführt mit Typo3. User finden sich schnell und einfach zurecht und gelangen rasch zu Produkten und Kontaktmöglichkeiten.',
    website: 'https://www.pan.it/de/grosshandel',
    logo: panlogo,
    backgroundImage: pan,
    color: '#ca1e27',
    altText: 'Website Pan'
  },
  {
    customer: 'Energie Tirol',
    project: 'Effiziente Projektverwaltung',
    description:
      'Für die Beratungsdienstleister Energie Tirol, bietet die neue Plattform eine zentrale Verwaltungsmöglichkeit alle Gemeinden sowie Projekte an einem zentralen Punkt zu verwalten. Es ist ein spannendes Projekt für einen guten Zweck und wir freuen uns, Teil davon sein zu dürfen.',
    website: 'https://www.energie-tirol.at/',
    logo: etlogo,
    backgroundImage: et,
    color: '#1158A0',
    detailPageLink: '/projekte/energietirol',
    altText: 'Website Energie Tirol'
  },
  {
    customer: 'Berufsreise',
    project: 'Plattform zur Berufsorientierung',
    description:
      'Wir haben für das WIFI Wirtschaftskammer Tirol das Webportal berufsreise.at entwickelt. Schüler, Lehrer, Eltern und Unternehmer können sich auf der Plattform austauschen, Profile erstellen, kommunizieren und Veranstaltungen teilen. Das Berufsreisespiel ist ein zentrales Element der Seite. Das Spiel ermöglicht das Sammeln von Punkten für zukünftige Prämien und Preise.',
    website: 'https://www.berufsreise.at/',
    logo: berufsreiselogo,
    backgroundImage: berufsreise,
    color: '#ca1e27',
    detailPageLink: '/projekte/berufsreise',
    altText: 'Website Berufsreise'
  },
  {
    customer: 'Colompac',
    project: 'Responsive Website mit praktischen Tools',
    description:
      'Eine tolle Website mit praktischen Tools. Per Filter ist es möglich sich direkt die richtige Verpackungsgröße berechnen zu lassen um so schnell das perfekte Produkt zu finden. ',
    website: 'https://www.colompac.com/',
    logo: colomlogo,
    backgroundImage: colompac,
    color: '#ca1e27',
    altText: 'Website Colompac'
  },
  {
    customer: 'Stubai ZMV GmbH',
    project: 'Relaunch Website',
    description:
      'Stubai - mehr als nur traumhafte Gebirgslandschaften, Gletscher & Natur pur? Das Gebiet unweit von Innsbruck hat sich nicht nur als Tourismusregion einen Namen gemacht! Das Stubaital bietet auch ein ideales Umfeld für die Produktion der hochwertigen STUBAI Werkzeuge.',
    website: 'https://www.stubai.com/de/start/',
    logo: stubailogo,
    backgroundImage: stubai,
    color: '#e30613',
    altText: 'Relaunch Website'
  },
  {
    customer: 'Wer Hilft Wie',
    project: 'Suchmaschine',
    description:
      'Das Team der Landesvolksanwaltschaft hat gemeinsam mit der Telefonseelsorge Innsbruck eine Suchmaschine entwickelt, die online rund 700 Einrichtungen im Sozial- und Behindertenbereich erfasst.',
    website: 'https://werhilftwie-tirol.at/',
    logo: whwlogo,
    backgroundImage: whw,
    detailPageLink: '/projekte/werhilftwie',
    color: '#ca1e27',
    altText: 'Zur Website'
  },
  {
    customer: 'Stadtmuseum Hall',
    project: 'Responsive Website mit integrierter Karriereseite',
    description:
      'Das Stadtmuseum Hall informiert über laufende und vergangene Ausstellungen und Führungen. Der Kunde bearbeitet diese Wordpress Webseite selbst und hält diese am aktuellen Stand.',
    website: 'https://stadtmuseumhall.at/',
    logo: museumhalllogo,
    backgroundImage: museumhall,
    color: '#ccae00',
    altText: 'Website Stadtmuseum Hall'
  },
  {
    customer: 'Bio Heizwerk Steinach am Brenner ',
    project: 'Responsive Website',
    description:
      'Die Bio Heizwerk Steinach a. Br. GmbH betreibt ein Nahwärmenetz im Gemeindegebiet Steinach am Brenner und wurde als Unterprojekt unseres langjährigen Kunden der Innsbrucker Kommunalbetriebe aufgenommen. Eine Wordpress Landingpage ist hier genau das Richtige. Mit klarer Leitlinie und direkter Verlinkung wie zum Beispiel zur Störhotline.',
    website: 'https://www.bioheizwerk-steinach.at/',
    logo: bhlogo,
    backgroundImage: bh,
    color: '#009a3d',
    altText: 'Website Bioheizwerk Steinach am Brenner'
  },
  {
    customer: 'Augenaerztin ',
    project: 'Landingpage',
    description:
      'Eine einfache Wordpress Landingpage mit klaren Strukturen und übersichtlicher Menüführung, erleichtert jedem Patienten und Interessierten von  Dr. med. univ. Quynh Anh Nguyen, schnell und unkompliziert einen Termin zu buchen, Kontaktdaten der Praxis zu erhalten oder an andere wichtige Information zu gelangen.',
    website: 'https://www.augenaerztin-innsbruck.at/',
    logo: aalogo,
    backgroundImage: aa,
    color: '#00a7e5',
    altText: 'Website Augenärztin Innsbruck'
  }
]

const ProjectOverview = () => {
  return (
    <div className="project-overview">
      <div className="project-overview__container">
        {projects.map((project, index) => (
          <ProjectOverviewItem {...project} key={index} />
        ))}
      </div>
    </div>
  )
}

export default ProjectOverview
