import React from 'react';
import Link from 'gatsby-link';
import './index.scss';

function SplitView({ displayImageLeft, title, text, linkText, link, backgroundImage, newTab, listHeading, listDescription, lists, textBoxes, small, mid, backgroundColor, center }) {
    const imagePosition = displayImageLeft ? 'left' : 'right';
    const contentPosition = !center ? 'flex-start !important' : '';

    function setImageStyle() {
        if (small) {
            return { height: '300px' };
        } else if (mid) {
            return { height: '500px' };
        }
        return {};
    }

    const splitViewStyle = {
        backgroundColor: backgroundColor || ''
    };

    const contentStyle = {
        justifyContent: `${contentPosition}`
    };

    return (
        <div className={`split-view split-view--${imagePosition}`} style={splitViewStyle}>
            <div className='split-view__container'>

                <div className="split-view__content" style={contentStyle}>
                    <h2>{title}</h2>
                    <span>{text}</span>
                    {!lists && !textBoxes && link && linkText && (
                        <button className="cta-round cta-round--on-text">
                            <span>
                                <Link to={link}>
                                    {linkText}
                                </Link>
                            </span>
                            <svg viewBox="0 0 13 10" height="10px" width="15px">
                                <path d="M1,5 L11,5"></path>
                                <polyline points="8 1 12 5 8 9"></polyline>
                            </svg>
                        </button>
                    )}
                </div>
                <div className='split-view__image' style={setImageStyle()}>
                    <img src={`${backgroundImage}`} alt="" />
                </div>
                {lists && (
                    <div className={"split-view__lists " + (lists.length > 2 ? 'columns' : 'rows')}>
                        <div className="lists-heading">
                            {listHeading && (
                                <h4>{listHeading}</h4>
                            )}
                            {listDescription && (
                                <p>{listDescription}</p>
                            )}
                        </div>
                        {lists.map(function (list, i) {
                            return <div className="list" key={i}>
                                {lists[i].head && (
                                    <div className="list-heading">{lists[i].head}</div>
                                )}
                                <ul className="list-content">
                                    {lists[i].content.map(function (bulletPoint, j) {
                                        return <li className="bullet-point" key={j}>{bulletPoint}</li>;
                                    })}
                                </ul>
                            </div>;
                        })}
                    </div>
                )}
                {textBoxes && (
                    <div className="individual-software__textBoxes">
                        {textBoxes}
                    </div>
                )}
                {(lists || textBoxes) && link && linkText && (
                    <button className="cta-round">
                        <span>
                            <Link to={link}>
                                {linkText}
                            </Link>
                        </span>
                        <svg viewBox="0 0 13 10" height="10px" width="15px">
                            <path d="M1,5 L11,5"></path>
                            <polyline points="8 1 12 5 8 9"></polyline>
                        </svg>
                    </button>
                )}
            </div>
        </div>
    );
}

export default SplitView;