import React from 'react'
import { mergeClasses } from '../../utils'
import './index.scss'

const HolzwegLogo = ({ className, color }) => (
  <svg className={ mergeClasses('holzweg-logo', className) } xmlns="http://www.w3.org/2000/svg" viewBox="0 0 570 160" fill={color ? color : 'white'}>
    <title>holzweg - design.strategie.technologie</title>
    <g>
      <title>background</title>
      <rect fill="none" id="canvas_background" height="162" width="572" y="-1" x="-1"/>
    </g>
    <g>
      <g stroke="null" id="svg_1">
        <g stroke="null" id="svg_2">
          <rect stroke="null" id="svg_3" height="31.671" width="31.672" fill="#F4A302" y="64.343" x="64.387"/>
          <rect stroke="null" id="svg_4" height="31.672" width="31.673" fill="#F08608" y="32.671" x="32.672"/>
          <rect stroke="null" id="svg_5" height="31.671" width="31.672" fill="#EC6316" y="1" x="64.387"/>
          <rect stroke="null" id="svg_6" height="31.671" width="31.672" fill="#EC6316" y="1" x="1"/>
          <rect stroke="null" id="svg_7" height="31.672" width="31.673" fill="#F5B616" y="96.022" x="32.672"/>
          <rect stroke="null" id="svg_8" height="31.67" width="31.672" fill="#F5B616" y="127.694" x="1"/>
        </g>
        <g stroke="null" id="svg_9">
          <path stroke="null" id="svg_10" d="m140.731,101.155l0,-70.179c0,-1.448 1.318,-2.175 3.955,-2.175l0.395,0c2.635,0 3.953,0.727 3.953,2.175l0,26.688c4.547,-4.546 9.588,-6.819 15.123,-6.819c3.758,0 7.15,0.956 10.182,2.866c1.646,1.121 2.981,2.803 4.004,5.041c1.021,2.241 1.531,4.942 1.531,8.105l0,34.298c0,1.451 -1.318,2.176 -3.953,2.176l-0.396,0c-2.636,0 -3.953,-0.725 -3.953,-2.176l0,-33.506c0,-2.966 -0.857,-5.305 -2.571,-7.02c-1.713,-1.712 -3.938,-2.568 -6.671,-2.568c-2.735,0 -5.14,0.576 -7.216,1.729c-2.076,1.152 -3.607,2.29 -4.596,3.409l-1.484,1.779l0,36.176c0,1.451 -1.318,2.176 -3.953,2.176l-0.395,0c-2.637,0.001 -3.955,-0.724 -3.955,-2.175z"/>
          <path stroke="null" id="svg_11" d="m222.792,103.725c-7.414,0 -13.082,-2.238 -17.002,-6.721c-3.922,-4.48 -5.881,-10.986 -5.881,-19.521c0,-8.534 1.977,-15.107 5.93,-19.721c3.954,-4.612 9.588,-6.919 16.904,-6.919c7.313,0 12.898,2.291 16.754,6.87c3.854,4.581 5.782,11.169 5.782,19.77c0,8.599 -1.896,15.123 -5.685,19.57c-3.789,4.45 -9.389,6.672 -16.802,6.672zm-10.824,-11.81c2.24,3.131 5.766,4.695 10.576,4.695c4.809,0 8.369,-1.564 10.676,-4.695c2.307,-3.129 3.46,-7.99 3.46,-14.581c0,-6.589 -1.153,-11.466 -3.46,-14.629s-5.867,-4.744 -10.676,-4.744c-4.811,0 -8.336,1.566 -10.576,4.695c-2.242,3.13 -3.361,8.007 -3.361,14.628c0,6.623 1.119,11.502 3.361,14.631z"/>
          <path stroke="null" id="svg_12" d="m267.647,90.085l0,-59.109c0,-1.448 1.317,-2.175 3.954,-2.175l0.395,0c2.635,0 3.953,0.727 3.953,2.175l0,58.32c0,4.877 1.78,7.314 5.339,7.314c1.118,0 2.075,-0.082 2.866,-0.248c0.791,-0.162 1.251,-0.246 1.383,-0.246c0.594,0 1.088,0.611 1.484,1.828c0.395,1.221 0.592,2.16 0.592,2.816c0,1.979 -2.406,2.965 -7.215,2.965c-3.296,0 -6.244,-1.002 -8.848,-3.014c-2.604,-2.009 -3.903,-5.55 -3.903,-10.626z"/>
          <path stroke="null" id="svg_13" d="m310.557,103.133c-0.396,0 -0.858,-0.592 -1.384,-1.779c-0.528,-1.186 -0.791,-2.125 -0.791,-2.816c0,-0.693 0.1,-1.203 0.297,-1.533l28.963,-38.45l-25.305,0c-1.32,0 -1.979,-0.989 -1.979,-2.967l0,-1.186c0,-1.977 0.658,-2.965 1.979,-2.965l34.693,0c0.46,0 0.906,0.609 1.335,1.828c0.428,1.22 0.644,2.174 0.644,2.866c0,0.691 -0.067,1.171 -0.197,1.434l-28.865,38.451l27.184,0c1.316,0 1.977,0.988 1.977,2.967l0,1.186c0,1.977 -0.66,2.965 -1.977,2.965l-36.574,0l0,-0.001z"/>
          <path stroke="null" id="svg_14" d="m369.853,52.92c0,-1.12 1.416,-1.681 4.25,-1.681l0.891,0c2.371,0 3.689,0.528 3.953,1.581l10.379,39.44l10.28,-39.34c0.263,-1.12 1.548,-1.681 3.854,-1.681l1.088,0c2.24,0 3.523,0.561 3.854,1.681l10.38,39.34l10.28,-39.44c0.264,-1.053 1.746,-1.581 4.448,-1.581c2.7,0 4.053,0.561 4.053,1.681c0,0.33 -0.099,0.79 -0.296,1.384l-13.246,47.445c-0.264,1.055 -1.582,1.582 -3.955,1.582l-3.261,0c-2.308,0 -3.593,-0.527 -3.856,-1.582l-9.092,-35.979l-9.292,35.979c-0.265,1.055 -1.582,1.582 -3.954,1.582l-3.262,0c-2.372,0 -3.691,-0.527 -3.954,-1.582l-13.245,-47.545c-0.2,-0.592 -0.297,-1.021 -0.297,-1.284z"/>
          <path stroke="null" id="svg_15" d="m463.92,97.301c-3.954,-4.283 -5.931,-10.824 -5.931,-19.621c0,-8.798 1.942,-15.47 5.831,-20.018c3.888,-4.546 9.423,-6.819 16.606,-6.819c13.969,0 20.956,8.6 20.956,25.8c0,0.592 -0.396,1.416 -1.188,2.47c-0.789,1.056 -1.481,1.582 -2.075,1.582l-31.235,0c0.396,5.471 1.812,9.489 4.251,12.059c2.438,2.57 6.094,3.855 10.971,3.855c4.481,0 8.602,-1.055 12.357,-3.164c1.25,-0.656 1.943,-0.988 2.076,-0.988c0.656,0 1.398,0.66 2.223,1.979c0.823,1.318 1.236,2.307 1.236,2.965c0,1.318 -1.797,2.703 -5.387,4.152c-3.594,1.449 -7.729,2.172 -12.405,2.172c-8.238,0 -14.332,-2.14 -18.286,-6.424zm28.665,-23.722c0,-10.412 -3.953,-15.618 -11.861,-15.618c-8.502,0 -13.115,5.206 -13.839,15.618l25.7,0z"/>
          <path stroke="null" id="svg_16" d="m521.018,111.141c0,-5.406 3.722,-9.424 11.169,-12.061c-4.219,-1.779 -6.326,-4.529 -6.326,-8.254c0,-3.723 2.504,-6.441 7.512,-8.154c-2.9,-1.383 -5.123,-3.376 -6.672,-5.981c-1.549,-2.603 -2.322,-5.518 -2.322,-8.747c0,-4.942 1.73,-9.027 5.189,-12.257c3.46,-3.229 8.318,-4.844 14.58,-4.844c2.372,0 4.547,0.197 6.523,0.594l17.001,0c1.317,0 1.978,0.988 1.978,2.965l0,1.186c0,1.978 -0.66,2.967 -1.978,2.967l-6.919,0c2.174,2.635 3.263,5.767 3.263,9.39c0,4.877 -1.747,8.929 -5.239,12.158c-3.494,3.23 -8.37,4.843 -14.629,4.843l-0.988,0c-5.67,0.264 -8.502,1.646 -8.502,4.152c0,1.383 1.153,2.422 3.461,3.113c2.305,0.691 5.09,1.234 8.352,1.631c3.263,0.395 6.523,0.955 9.785,1.68c3.262,0.727 6.045,2.191 8.354,4.398c2.307,2.209 3.459,5.092 3.459,8.65c0,4.48 -2.19,8.188 -6.574,11.119c-4.383,2.932 -10.278,4.398 -17.693,4.398c-7.412,0 -13.063,-0.973 -16.951,-2.916c-3.891,-1.944 -5.833,-5.286 -5.833,-10.03zm8.598,-0.89c0,4.48 4.943,6.721 14.827,6.721c4.414,0 7.989,-0.756 10.725,-2.271c2.734,-1.518 4.103,-3.396 4.103,-5.635c0,-1.781 -0.759,-3.1 -2.273,-3.955c-2.505,-1.385 -6.985,-2.504 -13.443,-3.361c-1.715,-0.262 -3.064,-0.494 -4.053,-0.689c-2.57,0.988 -4.86,2.271 -6.869,3.854c-2.013,1.58 -3.017,3.359 -3.017,5.336zm3.459,-42.356c0,6.622 3.707,9.935 11.121,9.935c7.413,0 11.121,-3.312 11.121,-9.935s-3.708,-9.934 -11.121,-9.934c-7.414,0 -11.121,3.312 -11.121,9.934z"/>
        </g>
        <g stroke="null" id="svg_17">
          <path stroke="null" id="svg_18" d="m140.732,137.755c0,-0.195 0.122,-0.488 0.367,-0.879c0.244,-0.391 0.464,-0.588 0.659,-0.588c0.02,0 0.176,0.094 0.47,0.279c0.293,0.186 0.713,0.371 1.261,0.557c0.547,0.188 1.207,0.279 1.979,0.279s1.408,-0.221 1.906,-0.66c0.499,-0.439 0.748,-1.035 0.748,-1.789c0,-0.752 -0.22,-1.338 -0.659,-1.76c-0.44,-0.42 -1.315,-0.816 -2.625,-1.188c-1.311,-0.371 -2.259,-0.846 -2.845,-1.422c-0.587,-0.576 -0.88,-1.451 -0.88,-2.625s0.449,-2.156 1.349,-2.947c0.899,-0.793 2.072,-1.188 3.52,-1.188c0.997,0 1.887,0.127 2.669,0.381c0.781,0.254 1.173,0.537 1.173,0.85c0,0.254 -0.112,0.582 -0.337,0.982c-0.226,0.402 -0.426,0.602 -0.602,0.602c-0.02,0 -0.146,-0.059 -0.381,-0.176c-0.665,-0.352 -1.413,-0.527 -2.244,-0.527s-1.477,0.172 -1.936,0.514c-0.46,0.342 -0.689,0.787 -0.689,1.334s0.171,0.977 0.514,1.289c0.342,0.314 0.924,0.598 1.745,0.852c1.838,0.566 3.084,1.188 3.739,1.861c0.654,0.676 0.982,1.691 0.982,3.051s-0.484,2.479 -1.452,3.357c-0.968,0.881 -2.126,1.32 -3.475,1.32c-1.35,0 -2.513,-0.199 -3.49,-0.602c-0.978,-0.4 -1.466,-0.784 -1.466,-1.157z"/>
          <path stroke="null" id="svg_19" d="m155.279,135.467l0,-9.355l-1.466,0c-0.392,0 -0.587,-0.293 -0.587,-0.881l0,-0.352c0,-0.586 0.195,-0.879 0.587,-0.879l1.466,0l0.353,-3.08c0.039,-0.43 0.352,-0.645 0.938,-0.645l0.293,0c0.587,0 0.88,0.215 0.88,0.645l0,3.08l2.464,0c0.391,0 0.587,0.293 0.587,0.879l0,0.352c0,0.588 -0.196,0.881 -0.587,0.881l-2.464,0l0,9.121c0,1.447 0.528,2.17 1.584,2.17c0.332,0 0.616,-0.023 0.851,-0.072s0.371,-0.074 0.41,-0.074c0.176,0 0.323,0.182 0.44,0.543s0.176,0.641 0.176,0.836c0,0.586 -0.714,0.879 -2.141,0.879c-0.979,0 -1.854,-0.297 -2.625,-0.895c-0.773,-0.596 -1.159,-1.647 -1.159,-3.153z"/>
          <path stroke="null" id="svg_20" d="m165.133,138.753l0,-10.295c0,-1.191 -0.049,-2.043 -0.146,-2.551c-0.138,-0.705 -0.205,-1.086 -0.205,-1.145c0,-0.234 0.186,-0.406 0.557,-0.514s0.66,-0.16 0.865,-0.16s0.347,0.02 0.426,0.059c0.077,0.039 0.15,0.102 0.22,0.189c0.068,0.088 0.122,0.176 0.161,0.266c0.039,0.088 0.078,0.205 0.117,0.352s0.073,0.264 0.103,0.352s0.059,0.215 0.088,0.381s0.054,0.279 0.073,0.338c1.036,-1.467 2.171,-2.199 3.402,-2.199c0.547,0 0.968,0.107 1.262,0.322c0.293,0.215 0.439,0.488 0.439,0.82c0,0.334 -0.059,0.68 -0.176,1.041c-0.117,0.363 -0.255,0.543 -0.411,0.543c-0.078,0 -0.269,-0.053 -0.571,-0.16c-0.304,-0.107 -0.665,-0.162 -1.085,-0.162c-0.421,0 -0.856,0.152 -1.306,0.455c-0.45,0.303 -0.782,0.6 -0.997,0.895l-0.352,0.439l0,10.734c0,0.43 -0.392,0.645 -1.174,0.645l-0.117,0c-0.782,-0.001 -1.173,-0.216 -1.173,-0.645z"/>
          <path stroke="null" id="svg_21" d="m184.724,129.778l0,-1.379c0,-1.643 -1.075,-2.463 -3.226,-2.463c-1.408,0 -2.709,0.303 -3.901,0.908c-0.41,0.217 -0.635,0.322 -0.674,0.322c-0.196,0 -0.416,-0.195 -0.66,-0.586c-0.245,-0.391 -0.366,-0.684 -0.366,-0.879c0,-0.412 0.571,-0.826 1.715,-1.248c1.145,-0.42 2.518,-0.629 4.121,-0.629c1.603,0 2.913,0.404 3.93,1.217c1.017,0.811 1.525,1.939 1.525,3.387l0,6.57c0,1.172 0.049,2.023 0.146,2.551c0.137,0.725 0.205,1.105 0.205,1.145c0,0.234 -0.186,0.406 -0.557,0.514c-0.372,0.107 -0.66,0.16 -0.865,0.16s-0.348,-0.02 -0.426,-0.059s-0.151,-0.107 -0.22,-0.205s-0.122,-0.191 -0.161,-0.279s-0.078,-0.209 -0.117,-0.365c-0.04,-0.156 -0.073,-0.279 -0.103,-0.367s-0.059,-0.225 -0.088,-0.41s-0.055,-0.309 -0.073,-0.367c-1.33,1.467 -2.973,2.199 -4.928,2.199c-1.506,0 -2.718,-0.371 -3.637,-1.113c-0.919,-0.744 -1.378,-1.887 -1.378,-3.432s0.532,-2.703 1.598,-3.477c1.065,-0.771 2.264,-1.24 3.593,-1.406c1.331,-0.166 2.846,-0.27 4.547,-0.309zm-7.098,5.191c0,1.604 0.831,2.404 2.493,2.404c0.958,0 1.784,-0.176 2.479,-0.527c0.693,-0.352 1.402,-0.852 2.126,-1.496l0,-3.461c-2.444,0 -4.018,0.137 -4.722,0.41c-0.371,0.137 -0.763,0.314 -1.173,0.529c-0.801,0.411 -1.203,1.124 -1.203,2.141z"/>
          <path stroke="null" id="svg_22" d="m192.994,135.467l0,-9.355l-1.466,0c-0.392,0 -0.587,-0.293 -0.587,-0.881l0,-0.352c0,-0.586 0.195,-0.879 0.587,-0.879l1.466,0l0.353,-3.08c0.039,-0.43 0.352,-0.645 0.938,-0.645l0.293,0c0.587,0 0.88,0.215 0.88,0.645l0,3.08l2.464,0c0.391,0 0.587,0.293 0.587,0.879l0,0.352c0,0.588 -0.196,0.881 -0.587,0.881l-2.464,0l0,9.121c0,1.447 0.528,2.17 1.584,2.17c0.332,0 0.616,-0.023 0.851,-0.072s0.371,-0.074 0.41,-0.074c0.176,0 0.323,0.182 0.44,0.543s0.176,0.641 0.176,0.836c0,0.586 -0.714,0.879 -2.141,0.879c-0.979,0 -1.854,-0.297 -2.625,-0.895c-0.772,-0.596 -1.159,-1.647 -1.159,-3.153z"/>
          <path stroke="null" id="svg_23" d="m203.582,137.608c-1.174,-1.27 -1.76,-3.211 -1.76,-5.822c0,-2.609 0.576,-4.59 1.73,-5.938c1.153,-1.35 2.796,-2.023 4.927,-2.023c4.146,0 6.218,2.551 6.218,7.654c0,0.176 -0.117,0.42 -0.352,0.732c-0.235,0.312 -0.44,0.469 -0.616,0.469l-9.268,0c0.117,1.625 0.537,2.816 1.261,3.578c0.724,0.764 1.809,1.145 3.256,1.145c1.329,0 2.551,-0.312 3.666,-0.938c0.371,-0.195 0.576,-0.295 0.615,-0.295c0.195,0 0.416,0.197 0.66,0.588s0.366,0.684 0.366,0.879c0,0.393 -0.532,0.803 -1.598,1.232c-1.066,0.43 -2.293,0.645 -3.681,0.645c-2.442,0 -4.251,-0.634 -5.424,-1.906zm8.505,-7.039c0,-3.088 -1.173,-4.633 -3.52,-4.633c-2.522,0 -3.891,1.545 -4.105,4.633l7.625,0z"/>
          <path stroke="null" id="svg_24" d="m217.336,141.714c0,-1.604 1.104,-2.795 3.314,-3.578c-1.252,-0.527 -1.877,-1.344 -1.877,-2.447c0,-1.105 0.742,-1.912 2.229,-2.42c-0.86,-0.41 -1.521,-1.002 -1.979,-1.775c-0.46,-0.771 -0.689,-1.637 -0.689,-2.596c0,-1.465 0.514,-2.678 1.54,-3.637c1.026,-0.957 2.468,-1.436 4.326,-1.436c0.703,0 1.349,0.059 1.936,0.176l5.044,0c0.392,0 0.587,0.293 0.587,0.879l0,0.352c0,0.588 -0.195,0.881 -0.587,0.881l-2.053,0c0.646,0.781 0.968,1.711 0.968,2.785c0,1.447 -0.519,2.65 -1.555,3.607c-1.036,0.959 -2.482,1.438 -4.34,1.438l-0.294,0c-1.682,0.078 -2.521,0.49 -2.521,1.232c0,0.41 0.342,0.719 1.026,0.924c0.684,0.205 1.51,0.367 2.478,0.484s1.937,0.283 2.904,0.498s1.793,0.65 2.478,1.305c0.685,0.656 1.026,1.51 1.026,2.566c0,1.33 -0.649,2.43 -1.95,3.299c-1.3,0.871 -3.05,1.307 -5.249,1.307c-2.2,0 -3.877,-0.289 -5.03,-0.865c-1.156,-0.579 -1.732,-1.571 -1.732,-2.979zm2.552,-0.264c0,1.33 1.467,1.994 4.399,1.994c1.31,0 2.37,-0.225 3.182,-0.674s1.218,-1.008 1.218,-1.672c0,-0.527 -0.226,-0.92 -0.675,-1.174c-0.743,-0.41 -2.073,-0.742 -3.989,-0.996c-0.508,-0.078 -0.909,-0.146 -1.202,-0.205c-0.763,0.293 -1.442,0.674 -2.038,1.143c-0.597,0.471 -0.895,0.998 -0.895,1.584zm1.026,-12.567c0,1.965 1.1,2.947 3.3,2.947c2.199,0 3.299,-0.982 3.299,-2.947s-1.1,-2.947 -3.299,-2.947c-2.2,0 -3.3,0.983 -3.3,2.947z"/>
          <path stroke="null" id="svg_25" d="m236.4,120.305c-0.782,0 -1.173,-0.215 -1.173,-0.646l0,-1.73c0,-0.43 0.391,-0.645 1.173,-0.645l0.352,0c0.782,0 1.174,0.215 1.174,0.645l0,1.73c0,0.432 -0.392,0.646 -1.174,0.646l-0.352,0zm-1.056,18.448l0,-14.166c0,-0.43 0.391,-0.645 1.173,-0.645l0.117,0c0.782,0 1.174,0.215 1.174,0.645l0,14.166c0,0.43 -0.392,0.645 -1.174,0.645l-0.117,0c-0.782,-0.001 -1.173,-0.216 -1.173,-0.645z"/>
          <path stroke="null" id="svg_26" d="m244.143,137.608c-1.174,-1.27 -1.76,-3.211 -1.76,-5.822c0,-2.609 0.576,-4.59 1.73,-5.938c1.153,-1.35 2.796,-2.023 4.927,-2.023c4.146,0 6.218,2.551 6.218,7.654c0,0.176 -0.117,0.42 -0.352,0.732c-0.235,0.312 -0.44,0.469 -0.616,0.469l-9.268,0c0.117,1.625 0.537,2.816 1.261,3.578c0.724,0.764 1.809,1.145 3.256,1.145c1.329,0 2.551,-0.312 3.666,-0.938c0.371,-0.195 0.576,-0.295 0.615,-0.295c0.195,0 0.416,0.197 0.66,0.588s0.366,0.684 0.366,0.879c0,0.393 -0.532,0.803 -1.598,1.232c-1.066,0.43 -2.293,0.645 -3.681,0.645c-2.443,0 -4.251,-0.634 -5.424,-1.906zm8.505,-7.039c0,-3.088 -1.173,-4.633 -3.52,-4.633c-2.522,0 -3.891,1.545 -4.105,4.633l7.625,0z"/>
          <path stroke="null" id="svg_27" d="m258.396,138.753l0,-1.936c0,-0.43 0.391,-0.646 1.173,-0.646l0.498,0c0.782,0 1.174,0.217 1.174,0.646l0,1.936c0,0.43 -0.392,0.645 -1.174,0.645l-0.498,0c-0.783,-0.001 -1.173,-0.216 -1.173,-0.645z"/>
          <path stroke="null" id="svg_28" d="m274.907,137.315c-1.407,1.467 -2.973,2.199 -4.692,2.199c-1.721,0 -3.094,-0.723 -4.121,-2.17c-1.026,-1.447 -1.539,-3.305 -1.539,-5.572l0,-0.264c0,-2.406 0.547,-4.287 1.642,-5.646c1.096,-1.357 2.464,-2.037 4.106,-2.037s3.108,0.498 4.399,1.494l0,-7.391c0,-0.43 0.391,-0.645 1.173,-0.645l0.117,0c0.782,0 1.174,0.215 1.174,0.645l0,17.07c0,1.172 0.048,2.023 0.146,2.551c0.137,0.725 0.205,1.105 0.205,1.145c0,0.234 -0.187,0.406 -0.558,0.514s-0.659,0.16 -0.865,0.16c-0.205,0 -0.347,-0.02 -0.425,-0.059s-0.151,-0.107 -0.22,-0.205c-0.069,-0.098 -0.123,-0.191 -0.162,-0.279s-0.078,-0.209 -0.117,-0.365s-0.073,-0.279 -0.103,-0.367s-0.059,-0.225 -0.088,-0.41s-0.052,-0.309 -0.072,-0.368zm-7.771,-5.865c0,3.949 1.271,5.924 3.812,5.924c1.271,0 2.521,-0.674 3.754,-2.023l0,-8.035c-1.056,-0.881 -2.239,-1.32 -3.549,-1.32l-0.176,0c-1.252,0.02 -2.205,0.498 -2.859,1.438c-0.655,0.936 -0.982,2.276 -0.982,4.016z"/>
          <path stroke="null" id="svg_29" d="m283.295,137.608c-1.174,-1.27 -1.76,-3.211 -1.76,-5.822c0,-2.609 0.576,-4.59 1.73,-5.938c1.153,-1.35 2.796,-2.023 4.927,-2.023c4.146,0 6.218,2.551 6.218,7.654c0,0.176 -0.117,0.42 -0.352,0.732c-0.235,0.312 -0.44,0.469 -0.616,0.469l-9.268,0c0.117,1.625 0.537,2.816 1.261,3.578c0.724,0.764 1.809,1.145 3.256,1.145c1.329,0 2.551,-0.312 3.666,-0.938c0.371,-0.195 0.576,-0.295 0.615,-0.295c0.195,0 0.416,0.197 0.66,0.588s0.366,0.684 0.366,0.879c0,0.393 -0.532,0.803 -1.598,1.232c-1.066,0.43 -2.293,0.645 -3.681,0.645c-2.442,0 -4.251,-0.634 -5.424,-1.906zm8.505,-7.039c0,-3.088 -1.173,-4.633 -3.52,-4.633c-2.522,0 -3.891,1.545 -4.105,4.633l7.625,0z"/>
          <path stroke="null" id="svg_30" d="m297.078,137.755c0,-0.195 0.122,-0.488 0.367,-0.879c0.244,-0.391 0.464,-0.588 0.659,-0.588c0.02,0 0.176,0.094 0.47,0.279c0.293,0.186 0.713,0.371 1.261,0.557c0.547,0.188 1.207,0.279 1.979,0.279s1.408,-0.221 1.906,-0.66c0.499,-0.439 0.748,-1.035 0.748,-1.789c0,-0.752 -0.22,-1.338 -0.659,-1.76c-0.44,-0.42 -1.315,-0.816 -2.625,-1.188c-1.311,-0.371 -2.259,-0.846 -2.845,-1.422c-0.587,-0.576 -0.88,-1.451 -0.88,-2.625s0.449,-2.156 1.349,-2.947c0.899,-0.793 2.072,-1.188 3.52,-1.188c0.997,0 1.887,0.127 2.669,0.381c0.781,0.254 1.173,0.537 1.173,0.85c0,0.254 -0.112,0.582 -0.337,0.982c-0.226,0.402 -0.426,0.602 -0.602,0.602c-0.02,0 -0.146,-0.059 -0.381,-0.176c-0.665,-0.352 -1.413,-0.527 -2.244,-0.527s-1.477,0.172 -1.936,0.514c-0.46,0.342 -0.689,0.787 -0.689,1.334s0.171,0.977 0.514,1.289c0.342,0.314 0.924,0.598 1.745,0.852c1.838,0.566 3.084,1.188 3.739,1.861c0.654,0.676 0.982,1.691 0.982,3.051s-0.484,2.479 -1.452,3.357c-0.968,0.881 -2.126,1.32 -3.475,1.32c-1.35,0 -2.513,-0.199 -3.49,-0.602c-0.977,-0.4 -1.466,-0.784 -1.466,-1.157z"/>
          <path stroke="null" id="svg_31" d="m312.212,120.305c-0.782,0 -1.173,-0.215 -1.173,-0.646l0,-1.73c0,-0.43 0.391,-0.645 1.173,-0.645l0.352,0c0.782,0 1.174,0.215 1.174,0.645l0,1.73c0,0.432 -0.392,0.646 -1.174,0.646l-0.352,0zm-1.056,18.448l0,-14.166c0,-0.43 0.391,-0.645 1.173,-0.645l0.117,0c0.782,0 1.174,0.215 1.174,0.645l0,14.166c0,0.43 -0.392,0.645 -1.174,0.645l-0.117,0c-0.782,-0.001 -1.173,-0.216 -1.173,-0.645z"/>
          <path stroke="null" id="svg_32" d="m317.579,141.714c0,-1.604 1.104,-2.795 3.314,-3.578c-1.252,-0.527 -1.877,-1.344 -1.877,-2.447c0,-1.105 0.742,-1.912 2.229,-2.42c-0.86,-0.41 -1.521,-1.002 -1.979,-1.775c-0.46,-0.771 -0.689,-1.637 -0.689,-2.596c0,-1.465 0.514,-2.678 1.54,-3.637c1.026,-0.957 2.468,-1.436 4.326,-1.436c0.703,0 1.349,0.059 1.936,0.176l5.044,0c0.392,0 0.587,0.293 0.587,0.879l0,0.352c0,0.588 -0.195,0.881 -0.587,0.881l-2.053,0c0.646,0.781 0.968,1.711 0.968,2.785c0,1.447 -0.519,2.65 -1.555,3.607c-1.036,0.959 -2.482,1.438 -4.34,1.438l-0.294,0c-1.682,0.078 -2.521,0.49 -2.521,1.232c0,0.41 0.342,0.719 1.026,0.924c0.684,0.205 1.51,0.367 2.478,0.484s1.937,0.283 2.904,0.498s1.793,0.65 2.478,1.305c0.685,0.656 1.026,1.51 1.026,2.566c0,1.33 -0.649,2.43 -1.95,3.299c-1.3,0.871 -3.05,1.307 -5.249,1.307c-2.2,0 -3.877,-0.289 -5.03,-0.865c-1.156,-0.579 -1.732,-1.571 -1.732,-2.979zm2.552,-0.264c0,1.33 1.467,1.994 4.399,1.994c1.31,0 2.37,-0.225 3.182,-0.674s1.218,-1.008 1.218,-1.672c0,-0.527 -0.226,-0.92 -0.675,-1.174c-0.743,-0.41 -2.073,-0.742 -3.989,-0.996c-0.508,-0.078 -0.909,-0.146 -1.202,-0.205c-0.763,0.293 -1.442,0.674 -2.038,1.143c-0.597,0.471 -0.895,0.998 -0.895,1.584zm1.026,-12.567c0,1.965 1.1,2.947 3.3,2.947c2.199,0 3.299,-0.982 3.299,-2.947s-1.1,-2.947 -3.299,-2.947c-2.2,0 -3.3,0.983 -3.3,2.947z"/>
          <path stroke="null" id="svg_33" d="m335.44,138.753l0,-10.295c0,-1.191 -0.049,-2.043 -0.146,-2.551c-0.138,-0.705 -0.206,-1.086 -0.206,-1.145c0,-0.234 0.186,-0.406 0.558,-0.514c0.371,-0.107 0.66,-0.16 0.865,-0.16s0.347,0.02 0.425,0.059s0.151,0.102 0.221,0.189c0.068,0.088 0.121,0.176 0.161,0.266c0.039,0.088 0.078,0.205 0.117,0.352s0.073,0.264 0.103,0.352s0.059,0.215 0.088,0.381s0.054,0.279 0.073,0.338c1.31,-1.467 2.771,-2.199 4.385,-2.199s2.845,0.42 3.695,1.26c0.851,0.842 1.276,2.006 1.276,3.49l0,10.178c0,0.43 -0.392,0.645 -1.174,0.645l-0.117,0c-0.782,0 -1.173,-0.215 -1.173,-0.645l0,-9.943c0,-0.898 -0.26,-1.598 -0.777,-2.096c-0.519,-0.5 -1.257,-0.748 -2.214,-0.748c-1.408,0 -2.64,0.684 -3.696,2.053l0,10.734c0,0.43 -0.391,0.645 -1.173,0.645l-0.117,0c-0.784,-0.002 -1.174,-0.217 -1.174,-0.646z"/>
          <path stroke="null" id="svg_34" d="m351.072,138.753l0,-1.936c0,-0.43 0.391,-0.646 1.173,-0.646l0.498,0c0.782,0 1.174,0.217 1.174,0.646l0,1.936c0,0.43 -0.392,0.645 -1.174,0.645l-0.498,0c-0.782,-0.001 -1.173,-0.216 -1.173,-0.645z"/>
          <path stroke="null" id="svg_35" d="m358.843,135.467l0,-9.355l-1.466,0c-0.392,0 -0.587,-0.293 -0.587,-0.881l0,-0.352c0,-0.586 0.195,-0.879 0.587,-0.879l1.466,0l0.353,-3.08c0.039,-0.43 0.352,-0.645 0.938,-0.645l0.293,0c0.587,0 0.88,0.215 0.88,0.645l0,3.08l2.464,0c0.391,0 0.587,0.293 0.587,0.879l0,0.352c0,0.588 -0.196,0.881 -0.587,0.881l-2.464,0l0,9.121c0,1.447 0.528,2.17 1.584,2.17c0.332,0 0.616,-0.023 0.851,-0.072s0.371,-0.074 0.41,-0.074c0.176,0 0.323,0.182 0.44,0.543s0.176,0.641 0.176,0.836c0,0.586 -0.714,0.879 -2.141,0.879c-0.979,0 -1.854,-0.297 -2.625,-0.895c-0.772,-0.596 -1.159,-1.647 -1.159,-3.153z"/>
          <path stroke="null" id="svg_36" d="m369.431,137.608c-1.174,-1.27 -1.76,-3.211 -1.76,-5.822c0,-2.609 0.576,-4.59 1.73,-5.938c1.153,-1.35 2.796,-2.023 4.927,-2.023c4.146,0 6.218,2.551 6.218,7.654c0,0.176 -0.117,0.42 -0.352,0.732c-0.235,0.312 -0.44,0.469 -0.616,0.469l-9.268,0c0.117,1.625 0.537,2.816 1.261,3.578c0.724,0.764 1.809,1.145 3.256,1.145c1.329,0 2.551,-0.312 3.666,-0.938c0.371,-0.195 0.576,-0.295 0.615,-0.295c0.195,0 0.416,0.197 0.66,0.588s0.366,0.684 0.366,0.879c0,0.393 -0.532,0.803 -1.598,1.232c-1.066,0.43 -2.293,0.645 -3.681,0.645c-2.443,0 -4.251,-0.634 -5.424,-1.906zm8.505,-7.039c0,-3.088 -1.173,-4.633 -3.52,-4.633c-2.522,0 -3.891,1.545 -4.105,4.633l7.625,0z"/>
          <path stroke="null" id="svg_37" d="m383.714,131.596c0,-1.467 0.2,-2.729 0.601,-3.783c0.4,-1.057 0.943,-1.867 1.628,-2.436c1.291,-1.035 2.864,-1.553 4.722,-1.553c1.134,0 2.151,0.137 3.051,0.41c0.898,0.273 1.349,0.586 1.349,0.938c0,0.256 -0.112,0.582 -0.337,0.982c-0.226,0.402 -0.426,0.602 -0.602,0.602c-0.02,0 -0.054,-0.01 -0.103,-0.029s-0.088,-0.033 -0.117,-0.043s-0.063,-0.025 -0.103,-0.045c-1.056,-0.469 -2.122,-0.703 -3.197,-0.703c-2.835,0 -4.252,1.867 -4.252,5.602c0,2.072 0.347,3.568 1.041,4.486c0.693,0.92 1.862,1.379 3.505,1.379c1.192,0 2.297,-0.254 3.313,-0.762c0.059,-0.02 0.122,-0.045 0.19,-0.074s0.112,-0.043 0.133,-0.043c0.194,0 0.41,0.189 0.645,0.57c0.235,0.383 0.353,0.689 0.353,0.924c0,0.354 -0.46,0.689 -1.379,1.012c-0.919,0.324 -1.994,0.484 -3.226,0.484c-2.425,0 -4.233,-0.625 -5.426,-1.877c-1.193,-1.25 -1.789,-3.265 -1.789,-6.041z"/>
          <path stroke="null" id="svg_38" d="m399.433,138.753l0,-20.824c0,-0.43 0.391,-0.645 1.173,-0.645l0.117,0c0.782,0 1.174,0.215 1.174,0.645l0,7.92c1.349,-1.35 2.845,-2.023 4.487,-2.023c1.114,0 2.121,0.283 3.021,0.85c0.488,0.332 0.885,0.832 1.188,1.496s0.455,1.467 0.455,2.404l0,10.178c0,0.43 -0.392,0.645 -1.174,0.645l-0.117,0c-0.782,0 -1.173,-0.215 -1.173,-0.645l0,-9.943c0,-0.879 -0.254,-1.572 -0.763,-2.082c-0.509,-0.508 -1.168,-0.762 -1.979,-0.762s-1.525,0.172 -2.141,0.514c-0.616,0.342 -1.071,0.68 -1.364,1.012l-0.439,0.527l0,10.734c0,0.43 -0.392,0.645 -1.174,0.645l-0.117,0c-0.784,-0.002 -1.174,-0.217 -1.174,-0.646z"/>
          <path stroke="null" id="svg_39" d="m416.151,138.753l0,-10.295c0,-1.191 -0.049,-2.043 -0.146,-2.551c-0.138,-0.705 -0.206,-1.086 -0.206,-1.145c0,-0.234 0.186,-0.406 0.558,-0.514c0.371,-0.107 0.66,-0.16 0.865,-0.16s0.347,0.02 0.425,0.059s0.151,0.102 0.221,0.189c0.068,0.088 0.121,0.176 0.161,0.266c0.039,0.088 0.078,0.205 0.117,0.352s0.073,0.264 0.103,0.352s0.059,0.215 0.088,0.381s0.054,0.279 0.073,0.338c1.31,-1.467 2.771,-2.199 4.385,-2.199s2.845,0.42 3.695,1.26c0.851,0.842 1.276,2.006 1.276,3.49l0,10.178c0,0.43 -0.392,0.645 -1.174,0.645l-0.117,0c-0.782,0 -1.173,-0.215 -1.173,-0.645l0,-9.943c0,-0.898 -0.26,-1.598 -0.777,-2.096c-0.519,-0.5 -1.257,-0.748 -2.214,-0.748c-1.408,0 -2.64,0.684 -3.696,2.053l0,10.734c0,0.43 -0.391,0.645 -1.173,0.645l-0.117,0c-0.784,-0.002 -1.174,-0.217 -1.174,-0.646z"/>
          <path stroke="null" id="svg_40" d="m438.689,139.514c-2.199,0 -3.881,-0.664 -5.044,-1.994c-1.164,-1.33 -1.745,-3.26 -1.745,-5.793c0,-2.531 0.586,-4.48 1.76,-5.85c1.173,-1.369 2.845,-2.053 5.015,-2.053c2.171,0 3.827,0.68 4.972,2.037c1.144,1.359 1.716,3.314 1.716,5.865c0,2.553 -0.562,4.488 -1.687,5.809c-1.126,1.319 -2.787,1.979 -4.987,1.979zm-3.211,-3.504c0.664,0.928 1.71,1.393 3.138,1.393c1.427,0 2.483,-0.465 3.168,-1.393c0.684,-0.93 1.026,-2.371 1.026,-4.326s-0.343,-3.402 -1.026,-4.34c-0.685,-0.939 -1.741,-1.408 -3.168,-1.408c-1.428,0 -2.474,0.465 -3.138,1.393c-0.665,0.93 -0.997,2.375 -0.997,4.34s0.332,3.412 0.997,4.341z"/>
          <path stroke="null" id="svg_41" d="m449.701,135.467l0,-17.539c0,-0.43 0.391,-0.645 1.173,-0.645l0.117,0c0.782,0 1.173,0.215 1.173,0.645l0,17.305c0,1.447 0.528,2.17 1.584,2.17c0.332,0 0.616,-0.023 0.851,-0.072s0.371,-0.074 0.41,-0.074c0.177,0 0.323,0.182 0.44,0.543s0.176,0.641 0.176,0.836c0,0.586 -0.714,0.879 -2.141,0.879c-0.979,0 -1.853,-0.297 -2.625,-0.895c-0.772,-0.596 -1.158,-1.647 -1.158,-3.153z"/>
          <path stroke="null" id="svg_42" d="m464.732,139.514c-2.199,0 -3.881,-0.664 -5.044,-1.994c-1.164,-1.33 -1.745,-3.26 -1.745,-5.793c0,-2.531 0.586,-4.48 1.76,-5.85c1.173,-1.369 2.845,-2.053 5.015,-2.053c2.171,0 3.827,0.68 4.972,2.037c1.144,1.359 1.716,3.314 1.716,5.865c0,2.553 -0.562,4.488 -1.687,5.809c-1.126,1.319 -2.787,1.979 -4.987,1.979zm-3.211,-3.504c0.664,0.928 1.71,1.393 3.138,1.393c1.427,0 2.483,-0.465 3.168,-1.393c0.684,-0.93 1.026,-2.371 1.026,-4.326s-0.343,-3.402 -1.026,-4.34c-0.685,-0.939 -1.741,-1.408 -3.168,-1.408c-1.428,0 -2.474,0.465 -3.138,1.393c-0.665,0.93 -0.997,2.375 -0.997,4.34s0.332,3.412 0.997,4.341z"/>
          <path stroke="null" id="svg_43" d="m474.336,141.714c0,-1.604 1.104,-2.795 3.314,-3.578c-1.252,-0.527 -1.877,-1.344 -1.877,-2.447c0,-1.105 0.742,-1.912 2.229,-2.42c-0.86,-0.41 -1.521,-1.002 -1.979,-1.775c-0.46,-0.771 -0.689,-1.637 -0.689,-2.596c0,-1.465 0.514,-2.678 1.54,-3.637c1.026,-0.957 2.468,-1.436 4.326,-1.436c0.703,0 1.349,0.059 1.936,0.176l5.044,0c0.392,0 0.587,0.293 0.587,0.879l0,0.352c0,0.588 -0.195,0.881 -0.587,0.881l-2.053,0c0.646,0.781 0.968,1.711 0.968,2.785c0,1.447 -0.519,2.65 -1.555,3.607c-1.036,0.959 -2.482,1.438 -4.34,1.438l-0.294,0c-1.682,0.078 -2.521,0.49 -2.521,1.232c0,0.41 0.342,0.719 1.026,0.924c0.684,0.205 1.51,0.367 2.478,0.484s1.937,0.283 2.904,0.498s1.793,0.65 2.478,1.305c0.685,0.656 1.026,1.51 1.026,2.566c0,1.33 -0.649,2.43 -1.95,3.299c-1.3,0.871 -3.05,1.307 -5.249,1.307c-2.2,0 -3.877,-0.289 -5.03,-0.865c-1.156,-0.579 -1.732,-1.571 -1.732,-2.979zm2.552,-0.264c0,1.33 1.467,1.994 4.399,1.994c1.31,0 2.37,-0.225 3.182,-0.674s1.218,-1.008 1.218,-1.672c0,-0.527 -0.226,-0.92 -0.675,-1.174c-0.743,-0.41 -2.073,-0.742 -3.989,-0.996c-0.508,-0.078 -0.909,-0.146 -1.202,-0.205c-0.763,0.293 -1.442,0.674 -2.038,1.143c-0.597,0.471 -0.895,0.998 -0.895,1.584zm1.026,-12.567c0,1.965 1.1,2.947 3.3,2.947c2.199,0 3.299,-0.982 3.299,-2.947s-1.1,-2.947 -3.299,-2.947c-2.2,0 -3.3,0.983 -3.3,2.947z"/>
          <path stroke="null" id="svg_44" d="m493.4,120.305c-0.782,0 -1.173,-0.215 -1.173,-0.646l0,-1.73c0,-0.43 0.391,-0.645 1.173,-0.645l0.352,0c0.782,0 1.174,0.215 1.174,0.645l0,1.73c0,0.432 -0.392,0.646 -1.174,0.646l-0.352,0zm-1.056,18.448l0,-14.166c0,-0.43 0.391,-0.645 1.173,-0.645l0.117,0c0.782,0 1.174,0.215 1.174,0.645l0,14.166c0,0.43 -0.392,0.645 -1.174,0.645l-0.117,0c-0.782,-0.001 -1.173,-0.216 -1.173,-0.645z"/>
          <path stroke="null" id="svg_45" d="m501.143,137.608c-1.174,-1.27 -1.76,-3.211 -1.76,-5.822c0,-2.609 0.576,-4.59 1.73,-5.938c1.153,-1.35 2.796,-2.023 4.927,-2.023c4.146,0 6.218,2.551 6.218,7.654c0,0.176 -0.117,0.42 -0.352,0.732c-0.235,0.312 -0.44,0.469 -0.616,0.469l-9.268,0c0.117,1.625 0.537,2.816 1.261,3.578c0.724,0.764 1.809,1.145 3.256,1.145c1.329,0 2.551,-0.312 3.666,-0.938c0.371,-0.195 0.576,-0.295 0.615,-0.295c0.195,0 0.416,0.197 0.66,0.588s0.366,0.684 0.366,0.879c0,0.393 -0.532,0.803 -1.598,1.232c-1.066,0.43 -2.293,0.645 -3.681,0.645c-2.443,0 -4.251,-0.634 -5.424,-1.906zm8.505,-7.039c0,-3.088 -1.173,-4.633 -3.52,-4.633c-2.522,0 -3.891,1.545 -4.105,4.633l7.625,0z"/>
        </g>
      </g>
    </g>
  </svg>
)


export default HolzwegLogo
