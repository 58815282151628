import React, { Component } from 'react'
import './index.scss'
import { scrollTo } from '../../utils'

import ArrowButton from '../ArrowButton'
import HoverSection from './HoverSection'

import Performance from '../../assets/performance.jpg'
import kompetenzen from '../../assets/kompetenzen.png'
import Technologie from '../../assets/technologie.jpg'
import Begeisterung from '../../assets/begeisterung.jpg'
import Balance from '../../assets/balance.jpg'
import chess from '../../assets/chessNew.jpg'

const HoverSections = [
  {
    header: 'Kompetenzen',
    text:
      'Von Design und Programmierung bis hin zu E-Commerce und benutzerfreundlichen Schnittstellen - entdecken Sie die vielfältigen Kompetenzen, die innovative Lösungen für anspruchsvolle Anforderungen ermöglichen. Wir verschieben Grenzen und setzen neue Maßstäbe.',
    link: '/kompetenzen',
    linkText: 'qualitätsbewusst',
    modifier: 'big',
    image: kompetenzen
  },
  {
    header: 'Strategie',
    text:
      'Unsere Innsbrucker Webagentur setzt auf Kundenzufriedenheit durch erprobte Abläufe, ein benutzerfreundliches Customer Portal und Ticketsystem. Erfahren Sie mehr über unsere Strategie und Arbeitsweise.',
    link: '/strategie',
    linkText: 'effektiv',
    modifier: 'small',
    image: chess
  },
  {
    header: 'Technologie',
    text:
      'Mit Qualität und Kompetenz haben wir über 250 Kunden seit 1995 betreut. In gemeinsamen Beratungen eruieren wir Ihren Bedarf und helfen Ihnen zu einem qualitativ hochwertigen Webauftritt mit sicherem Einsatz von neusten Technologien!',
    link: '/technologie',
    linkText: 'zuverlässig',
    modifier: 'small',
    image: Technologie
  },
  {
    header: 'Projekte',
    text:
      'Entdecken Sie unsere ansprechenden Projekte, die sich durch Nutzerfreundlichkeit und individuelle Anpassung auszeichnen. Erfahren Sie, wie wir maßgeschneiderte Lösungen entwickeln, um die Bedürfnisse unserer Kund:innen optimal zu erfüllen.',
    link: '/projekte',
    linkText: 'maßgeschneidert',
    modifier: 'big',
    image: Balance
  }
]

class BannerFull extends Component {
  constructor (props) {
    super(props)
  }

  state = {
    activeHeaderItem: 0
  }

  container = null

  setContainerRef = e => {
    this.container = e
  }

  scroll = () => {
    const { bottom } = this.container.getBoundingClientRect()
    scrollTo(window.scrollY + bottom)
  }

  createSetActivateHeaderItem = activeHeaderItem => () =>
    this.setState({ activeHeaderItem })

  render () {
    return (
      <div className="banner-full" ref={this.setContainerRef}>
        {HoverSections.map(({ image }, index) => (
          <link rel="prefetch" href={image} key={index} />
        ))}
        <div className="banner-full__center">
          <div className="banner-full__filter-overlay" />
          {HoverSections.map((section, index) => (
            <HoverSection
              {...section}
              isActive={this.state.activeHeaderItem === index}
              onMouseEnter={this.createSetActivateHeaderItem(index)}
              key={index}
            />
          ))}
        </div>
        <div className="banner-full__bottom">
          <ArrowButton mods={['color-white', 'down']} onClick={this.scroll} />
        </div>
      </div>
    )
  }
}

export default BannerFull
