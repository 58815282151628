import React from 'react'
import CallUsCard from './CallUsCard'
import './index.scss'

const CallUs = ({ contacts = [], title }) => (
  <div className="call-us">
    <div className="call-us__headline headline headline--centered">
      <h2>{title}</h2>
    </div>
    <div className="call-us__container">
      {contacts.map((contact, i) => (
        <CallUsCard {...contact} key={i} />
      ))}
    </div>
  </div>
)

export default CallUs
