import React from 'react'
import Link from 'gatsby-link'

import './index.scss'

const NotFound = () => (
  <div className="notfound">
    <h2 className="glitch" data-text="404">
      404
    </h2>
    <p>Ups! Diese Seite existiert nicht.</p>
    <Link to={'/'} className="button">
      Zur Startseite
    </Link>
  </div>
)

export default NotFound
