import React, { useState, useEffect } from 'react';
import './index.scss';
import cookie from '../../assets/cookie.png';

const PopupCookieConsentBox = () => {
    const [hasConsented, setHasConsented] = useState(false);
    const [cookieBannerVisible, setCookieBannerVisible] = useState(true);

    useEffect(() => {
        const storedConsent = localStorage.getItem('showCookieBanner');
        if (storedConsent !== 'true') {
            setCookieBannerVisible(false);
        } else {
            setCookieBannerVisible(true);
        }
    }, []);

    const acceptCookies = () => {
        setCookieBannerVisible(true);
        setHasConsented(true);
        localStorage.setItem('showCookieBanner', 'true');
    };

    const declineCookies = () => {
        setCookieBannerVisible(true);
        setHasConsented(false);
        localStorage.setItem('showCookieBanner', 'true');
    };

    useEffect(() => {
        if (hasConsented) {
            const script = document.createElement('script');
            script.src = 'https://www.googletagmanager.com/gtag/js?id=G-2CBC5JS5XE';
            script.async = true;
            document.body.appendChild(script);

            const inlineScript = document.createElement('script');
            inlineScript.innerHTML = `
                window.dataLayer = window.dataLayer || [];
                function gtag() {dataLayer.push(arguments);}
                gtag('consent', 'default', {
                    'ad_user_data': 'denied',
                    'ad_personalization': 'denied',
                    'ad_storage': 'denied',
                    'analytics_storage': 'denied',
                    'wait_for_update': 500,
                });
                gtag('js', new Date());
                gtag('config', 'G-2CBC5JS5XE');

                function acceptGtag() {
                    gtag('consent', 'update', {
                        'ad_storage': 'granted',
                        'ad_user_data': 'granted',
                        'ad_personalization': 'granted',
                        'analytics_storage': 'granted'
                    });
                    console.log(true);
                }
            `;
            document.body.appendChild(inlineScript);
            acceptGtag();
        } 
        else {
            const scripts = document.querySelectorAll('script[src^="https://www.googletagmanager.com/gtag/js"]');
            scripts.forEach(script => {
                script.parentNode.removeChild(script);
            });

            const inlineScripts = document.querySelectorAll('script[data-gtm-inline]');
            inlineScripts.forEach(script => {
                script.parentNode.removeChild(script);
            });
        }
    }, [hasConsented]);

    return (
        <div className={`cookiebox ${cookieBannerVisible ? 'cookiebox__closed' : ''}`}>
            <header className='cookiebox__header'>
                <img src={cookie} alt="Cookie Icon" className="cookie__icon" />
                <h2>Wir nutzen Cookies!</h2>
            </header>

            <div className="cookiebox__text">
                <p>Diese Website verwendet Cookies, um Ihr Erlebnis zu optimieren und Ihnen relevante Inhalte bereitzustellen. <a href="/datenschutzerklaerung"> Mehr erfahren...</a></p>
            </div>

            <div className="cookiebox__buttons">
                <button onClick={declineCookies}>Ablehnen</button>
                <button onClick={acceptCookies}>Akzeptieren</button>
            </div>
        </div>
    );
};

export default PopupCookieConsentBox;