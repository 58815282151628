import React from 'react'
import ShowElement from '../../ShowElement'
import './index.scss'

const CustomerSliderItem = ({
  project,
  title,
  topDescription,
  personName,
  personFunction,
  personImage,
  bottomDescription,
  logo,
  mainImage,
  link,
  linkText
}) => {
  return (
    <div className="customer-slider-item">
      <div className="customer-slider-item__top-section">
        <ShowElement
          className="customer-slider-item__main-image"
          threshold="0.08"
        >
          {showElement => (
            <div className={showElement ? 'animate' : ''}>
              <img src={mainImage} />
              <div className="customer-slider-item__logo">
                <img src={logo} alt={title} />
                <span>Projekt: {project}</span>
                <span>{title}</span>
              </div>
            </div>
          )}
        </ShowElement>
        <ShowElement
          className="customer-slider-item__description-top"
          threshold="0.2"
        >
          {showElement => (
            <div className={showElement ? 'animate' : ''}>
              {topDescription}
              <a
                href={link}
                target="_self"
                className="button button--color-black"
              >
                {linkText}
              </a>
            </div>
          )}
        </ShowElement>
      </div>
      <ShowElement
        className="customer-slider-item__center-section"
        threshold="0.2"
      >
        {showElement => (
          <div className={showElement ? 'animate' : ''}>
            {
              personName || personFunction || personImage
              ? <div className="customer-slider-item__person">
                  <img src={personImage} alt={personName} />
                  <div className="customer-slider-item__person-detail">
                    <h4>{personName}</h4>
                    <span>{personFunction}</span>
                  </div>
                </div>
              : ''
            }
            <div className="customer-slider-item__description-bottom">
              {bottomDescription}
            </div>
          </div>
        )}
      </ShowElement>
    </div>
  )
}

export default CustomerSliderItem
