import React, { Component } from 'react'
import Swiper from 'react-id-swiper/lib/custom'
import 'react-id-swiper/src/styles/scss/swiper.scss'
import ArrowButton from '../ArrowButton'
import { exposeSliderMixin, getFormatedDate } from '../../utils'
import axios from 'axios'
import './index.scss'

import hwlogo from '../../assets/holzweg.logo.png'

class FacebookFeed extends exposeSliderMixin(Component) {
  state = {
    feed: [],
    placeholderCount: [1, 2, 3, 4]
  }

  componentDidMount() {
    axios
      .get(
        `https://graph.facebook.com/v3.2/holzwegwebagentur/feed?fields=story,id,message,full_picture,picture,link,from,created_time,caption,permalink_url&access_token=${
          process.env.GATSBY_FACEBOOK_API_TOKEN
        }`
      )
      .then(response =>
        this.setState({ feed: response.data.data }, () => {
          this.swiper.update()
          this.checkBoundaries()
        })
      )
  }

  render = () => {
    const options = {
      loop: false,
      slidesPerView: 4,
      freeMode: true,
      centeredSlides: false,
      spaceBetween: 20,
      speed: 300,
      breakpoints: {
        1300: {
          slidesPerView: 3
        },
        1000: {
          slidesPerView: 2
        },
        630: {
          slidesPerView: 1
        }
      }
    }
    return 0;
    // return (
      // <div className="facebook-feed">
      //   <div className="facebook-feed__headline headline headline--centered">
      //     <h2>
      //       #people
      //       <wbr />
      //       of
      //       <wbr />
      //       holzweg
      //     </h2>
      //   </div>
      //   <div
      //     className="facebook-feed__container"
      //     onTransitionEnd={this.handleDrag}
      //   >
      //     <Swiper {...options} ref={this.onInit}>
      //       {this.state.feed.length
      //         ? this.state.feed.map(
      //             ({
      //               message,
      //               id,
      //               full_picture,
      //               from,
      //               created_time,
      //               caption,
      //               permalink_url
      //             }) => (
      //               <div className="facebook-feed__item" key={id}>
      //                 <div className="facebook-feed__image-wrapper">
      //                   {full_picture ? (
      //                     <img
      //                       src={full_picture}
      //                       className="facebook-feed__image"
      //                     />
      //                   ) : (
      //                     ''
      //                   )}
      //                 </div>
      //                 <div className="facebook-feed__message">
      //                   <div className="facebook-feed__header">
      //                     <img className="logo" src={hwlogo} />
      //                     <div>
      //                       <a
      //                         href="https://www.facebook.com/holzwegwebagentur"
      //                         target="_blank"
      //                       >
      //                         <b>{from.name}</b>
      //                       </a>
      //                       <p>{getFormatedDate(new Date(created_time))}</p>
      //                     </div>
      //                   </div>
      //                   <h5>{caption}</h5>
      //                   <p>{message}</p>
      //                 </div>
      //                 <a
      //                   href={permalink_url}
      //                   target="_blank"
      //                   className="facebook-feed__link"
      //                 />
      //               </div>
      //             )
      //           )
      //         : this.state.placeholderCount.map((value, index) => (
      //             <div
      //               className="facebook-feed__item facebook-feed__item--placeholder"
      //               key={index}
      //             >
      //               <div className="facebook-feed__placeholder-bg facebook-feed__image" />
      //               <div className="facebook-feed__message">
      //                 <div className="facebook-feed__header">
      //                   <div className="facebook-feed__placeholder-image" />
      //                   <div className="facebook-feed__placeholder-text-wrapper">
      //                     <div className="facebook-feed__placeholder-text" />
      //                     <div className="facebook-feed__placeholder-text" />
      //                   </div>
      //                 </div>
      //                 <div className="facebook-feed__placeholder-content">
      //                   <div className="facebook-feed__placeholder-text" />
      //                   <div className="facebook-feed__placeholder-text" />
      //                   <div className="facebook-feed__placeholder-text" />
      //                   <div className="facebook-feed__placeholder-text" />
      //                 </div>
      //               </div>
      //             </div>
      //           ))}
      //     </Swiper>
      //   </div>
      //   <div className="facebook-feed__bottom">
      //     <div className="facebook-feed__button-wrapper">
      //       <ArrowButton
      //         mods={this.getModsLeft()}
      //         onClick={this.prevSlide}
      //         color="white"
      //       />
      //       <ArrowButton
      //         mods={this.getModsRight()}
      //         onClick={this.nextSlide}
      //         color="white"
      //       />
      //     </div>
      //   </div>
      // </div>
    // )
  }
}

export default FacebookFeed
