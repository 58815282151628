import React from "react";
import './index.scss'
import zahnrad from '../../assets/zahnrad.png'

const items = [
    {heading:"KFZ Lerinhalte Digital", text:"KFZ Lehrinhalte immer und überall griffbereit. Mit digitalen und unterschiedlichen Inhaltsformaten bleibt das Lernen spannend."},
    {heading:"Wissensüberprüfung", text:"Durch Fragepools, Quizze, offene Fragen und Erklärungen kann Ihr Lehrling effektiv Wissen erwerben und sich leicht auf die LAP vorbereiten."},
    {heading:"Chancengleichheit", text:"Setzen Sie mit unserer App ein Zeichen für die Wertschätzung der Lehrlingsausbildung. Auch kleine Betriebe können sich durch die App einen Vorteil verschaffen."},
    {heading:"Digital & Jederzeit griffbereit", text:"Die Lehrinhalte sind auf mobilen Endgeräten so wie auch am Desktop immer griffbereit. Praktisch für den Alltag und ideal für die Prüfungsvorbereitung."},
    {heading:"Anpassbarkeit & Erweiterungen", text:"Sie haben die Möglichkeit, dass die App an die Bedürfnisse des Betriebes angepasst und erweitert werden kann."},
];

const erweiterungen = [
    {heading:"Betriebs-Standards Digitalisiert", text:"Halten Sie Ihre Abläufe und Standards digital fest und sparen Sie sich Zeit bei der Einarbeitung. Die Inhalte (Das FirmenWiki) sind jederzeit für Ihre MitarbeiterInnen zugänglich."},
    {heading:"KFZ Fachbegriffe - Übersetzungstool", text:"Für nicht deutschsprachige Fachkräfte ein Fachbegriffs-Wörterbuch mit Vorlesefunktion und Erklärung in gewählter Sprache. Zudem eine schnelle Übersetzung von Sätzen ins Deutsche."},
    {heading:"Internes Mitarbeiter Schulungstool", text:"Bereiten Sie Ihren Betrieb bestmöglich auf die Zukunft vor. Digitalisieren Sie neue Zertifizierung- und Zugangsstandard für sicherheitsrelevante Reparatur- und Wartungsinformationen und bleiben Sie und Ihr Team immer auf dem neuesten Stand."},
    {heading:"Intranet - Kommunikation vereinfacht", text:"Normblätter, Kalender, Chatfunktion - alles in einem Tool. Machen Sie den internen Austausch attraktiv, stärken Sie den Zusammenhalt im Team und somit auch die Mitarbeiterbindung."},
    {heading:"Individuelle Wünsche?", text:"Es gibt vielleicht noch weitere Herausforderungen, vor denen Sie stehen. Wir sind uns sicher, dass wir eine Lösung für Sie finden. Verschaffen Sie sich einen Wettbewerbsvorteil und gehen Sie mit der Zeit."},
]

const LernApp = () => {
    return (
        <div className="lernapp">
            <h3>Die Lern-App für Ihen KFZ-Lernbetrieb</h3>

            <div className="lernapp__container">
                {items.map((item, index) => (
                    <div className="lernapp-item" key={item.heading}>
                        <div className="index-box">{index + 1}</div>
                        <div>
                            <h4>{item.heading}</h4>
                            <p>{item.text}</p>
                        </div>
                    </div>
                ))}
            </div>
            <div className="lernapp-erweiterbar">
            <h3>Mögliche Erweiterungen</h3>
            {erweiterungen.map((item) => (
                    <div className="lernapp-item" key={item.heading}>
                        <div className="index-box">
                            <img src={zahnrad}/>
                        </div>
                        <div>
                            <h4>{item.heading}</h4>
                            <p>{item.text}</p>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    )
}

export default LernApp