import React from 'react'
import Link from 'gatsby-link'
import './index.scss'

const TextBox = ({
  title,
  text,
  image,
}) => {
  return (
    <div className="map">
      <div className="map__container">
        <div className="map__wrapper">
          <div className="map__left">
            <div className="map__content">
              <div className="map__headline headline">
                <h2>{title}</h2>
              </div>
              {text}
            </div>
          </div>
          <div className="map__right">
            <a href="https://www.google.at/maps/place/holzweg+Gmbh+-+Webagentur/@47.2624746,11.3961963,17z/data=!3m1!4b1!4m5!3m4!1s0x479d6bfc2613f039:0x29c8ffe08546b77!8m2!3d47.262471!4d11.398385" target="_blank" className="button">Google Maps öffnen</a>
            <img src={image} alt="Google Maps Karte"/>
          </div>
        </div>
      </div>
    </div>
  )
}

export default TextBox
