import React from 'react'
import Link from 'gatsby-link'
import './index.scss'

const TextBox = ({
  title,
  subTitle,
  text,
  afterText,
  image,
  buttons,
  imagepos,
  id,
}) => {
    return (
        <div {...(id && {id})} className={`text-image`}>
            {title.length !== 0 ? (
                <h4 className={`text-image__title ${imagepos ? imagepos : ''}`}>{title}</h4>
            ) : (
            ''
            )}
            {
            subTitle
            ? <div className={`text-image__subtitle ${imagepos ? imagepos : ''}`}>{subTitle}</div>
            : <div />
            }
            <div className={`text-image__content ${imagepos ? imagepos : ''}`}>
            <div className={`text-image__text`}>{text}</div>
            {afterText}
            <img src={image} />
            </div>
            <div className="text-image__button-wrapper">
            {
                buttons && buttons.map(({link, text, color}, index) => (
                link.includes('http') ? (
                    <a
                    href={link}
                    target="_blank"
                    className={`button button--color-${color ? color : 'black'}`}
                    key={index}
                    >
                    {text}
                    </a>
                ) : (
                    <Link to={link} className={`button button--color-${color ? color : 'black'}`} key={index}>
                    {text}
                    </Link>
                )
                ))
            }
            </div>
        </div>
    )
}

export default TextBox
