import React from 'react'
import Link from 'gatsby-link'
import './index.scss'

const Breadcrumbs = ({ links, backLinkAlias }) => {
  const backLink = links[links.length - 2]
  return (
    <div className="breadcrumbs">
      <ul className="breadcrumbs__links">
        {links.map((l, i) => (
          <li key={l.path}>
            <Link to={l.path}>{l.name}</Link>
            {i < links.length - 1 ? (
              <span className="breadcrumbs__spacer">/</span>
            ) : null}
          </li>
        ))}
      </ul>
      <Link to={backLink.path} className={'back-link'}>
        {/* {'<'} */}
        {backLinkAlias || backLink.name}
      </Link>
    </div>
  )
}

export default Breadcrumbs
