import React from 'react'
import './index.scss'

const BannerCTA = ({ title, text }) => {
  return (
    <>
      <div className='bannercta'>
        <div className='bannercta__container'>
          <h2>{title}</h2>
          <p>
            {text}
          </p>
          <span>
            <button>
              <a href="/individual-software">
                Mehr erfahren
              </a>
            </button>
            <button>
              <a href="/kontakt">
                Kontaktieren Sie Uns!
              </a>
            </button>
          </span>
        </div>
      </div>
    </>
  )
}

export default BannerCTA
        // <p>
        //   {text}
        //     Sie wollen Ihre Geschäftsprozesse nicht an eine Standard-Software
        //     anpassen? Dann wird es <b>höchste Zeit</b>, auf eine für Ihr
        //     Unternehmen{' '}
        //     <b>
        //       perfekt angepasste Individual-Software umzusteigen. {' '}
        //     </b>
        //      Sichern Sie Ihrem Unternehmen, vor allem jetzt, in diesen Zeiten,{' '}
        //     einen <b>sicheren Wettbewerbsvorteil</b>.{' '}
        //   </p>